.mile-marker-accrual-log-page-container {
  .currency-style,
  .defaultPrice {
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 16px !important;
  }

  .SectionWrapper {
    background-color: #fff;
    padding: 40px 20px 20px 20px;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    .title {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
    }
    .id {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
    }
  }
  .log-container {
  }
}
