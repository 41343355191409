.myTrips-container {
  background-color: #f5f5f5;
  .SectionWrapper {
    background-color: #f5f5f5;
  }
  height: 100%;
  .tabs-navigation {
    padding-bottom: 24px;
  }
}
.my-trip-manage-link {
  display: block;
  margin: 20px 8px;
  text-decoration: none;
  color: #3c3835;
  font-size: 18px;
}

.my-trip-manage-link:hover {
  color: rgb(250, 181, 0);
}
