.top-menu-logo-wrapper {
  height: 24px;
  img {
    max-width: 140px;
    height: 24px;
  }
  z-index: 1;

  @media screen and (max-width: 1100px) {
  }
}
