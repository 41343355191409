.credit-card-form__wrapper {
  border-radius: 6px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  padding: 40px 45px;

  .credit-card-form__header {
    border-bottom: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 16px;
  }

  .credit-card-form__fieldset-wrapper {
    padding-left: 10px;

    label {
      background: #f5f5f5 !important;
      font-weight: 400;
      font-size: 8.5px;
      line-height: 10px;
      letter-spacing: 0.05em;
      left: 10px;
      top: -5px;
      padding: 0 5px;
    }
  }
}

.payment-buttons {
  button {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background: #fab500;
    color: #000;
  }
}
