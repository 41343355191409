.corporate-travel-section {
  max-width: 1100px;
  margin: auto;
}

.corporate-travel-section-title {
  margin: 2rem 2rem 1.2rem;
  font-size: 28px;
  color: #111111;
  display: flex;
  justify-content: center;
  @media (min-width: 600px) {
    font-size: 32px;
    margin: 4rem 2rem 1.2rem;
  }
}

.corporate-travel-description-with-cta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0rem 2rem;
  p {
    color: #212121;
    line-height: 1.25;
    font-size: 16px;
    @media (min-width: 600px) {
      font-size: 18px;
    }
  }
  a {
    background-color: #ffb404;
    color: #111111;
    font-weight: 600;
    padding: 0.3rem 2rem;
    border-radius: 1.1rem;
    margin: 1rem;
    font-size: 20px;
    align-self: center;
    @media (min-width: 600px) {
      font-size: 24px;
    }
  }
}

.corporate-travel-iconic-description-card {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  gap: 1rem;
  @media (min-width: 600px) {
    flex-direction: row;
    margin: 2rem 5rem;
    gap: 2rem;
  }
}

.corporate-travel-card-icon {
  background-color: #fab500;
  display: flex;
  align-items: center;
  min-width: 8rem;
  min-height: 7rem;
  justify-content: center;
  border-radius: 1rem;
  align-self: center;
  svg {
    height: 5rem;
    width: 5rem;
    color: white;
    @media (min-width: 600px) {
      height: 7rem;
      width: 7rem;
    }
  }
  @media (min-width: 600px) {
    min-width: 11rem;
    min-height: 9rem;
  }
}

.corporate-travel-card-info {
  display: flex;
  flex-direction: column;
  align-self: anchor-center;
  gap: 0.5rem;
  h2 {
    font-size: 20px;
    @media (min-width: 600px) {
      font-size: 24px;
    }
  }
  p {
    line-height: 1.25;
    font-size: 16px;
    @media (min-width: 600px) {
      font-size: 18px;
    }
  }
}

.corporate-travel-service-benefits-container {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  margin: 0 2rem 1rem;
  div {
    flex: 1;
  }
  > div:first-child {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  img {
    width: 100%;
    max-height: 300px;
    border-radius: 1rem;
    height: auto;
    margin: 1rem 2rem;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.corporate-travel-service-benefits-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1.25;
    p {
      font-size: 16px;
      @media (min-width: 600px) {
        font-size: 18px;
      }
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: #13ab25;
      @media (min-width: 600px) {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
