@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";

.B2BWhereToFindUs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10% 0;
  @include resp(650px) {
    padding: 13.333% 0 64.167% 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .B2BWhereToFindUsImage {
    @include resp(1100px) {
      width: 100%;
    }

    @include resp(475px) {
      width: unset;
      height: 181px;
      position: absolute;
      right: 6.667%;
    }
    // @include resp
  }
  .textContent {
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: center;
    @include resp(650px) {
      gap: 24px;
      margin-bottom: 56px;
    }
    .B2BWhereToFindUsTitle {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 0;
      @include resp(1100px) {
        font-size: 24px;
        line-height: 32px;
      }
      @include resp(650px) {
        font-size: 16px;
        line-height: 16px;
      }
    }
    .address {
      font-size: 24px;
      line-height: 32px;
      @include resp(1100px) {
        font-size: 20px;
        line-height: 25px;
      }
      @include resp(650px) {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .freeParking {
      font-style: italic;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 0;
      @include resp(1100px) {
        font-size: 24px;
        line-height: 32px;
      }
      @include resp(650px) {
        font-size: 16px;
        line-height: 38px;
      }
    }
  }
}
