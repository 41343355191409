.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 40px 0 20px 0;
  font-family: "Source Sans 3", sans-serif !important;
}
.stores-icons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  @media screen and (max-width: 750px) {
    justify-content: center;
  }
}
.stores-icons-container img:first-child {
  margin-right: 16px;
}

.logo-links {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-around;
  padding-left: 40px;
  padding-right: 40px;
}
.footerLogo {
  width: 180px;
  height: 60px;
  border: 0px solid #fff;
}
.links {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 750px) {
    align-self: center;
    justify-content: center;
  }
}
.links a {
  font-family: "Source Sans 3", sans-serif;
  color: white;
  padding: 10px 0px 4px 0px;
  margin: 0px 20px;
  text-decoration: none;
  font-size: 16px;
  border-bottom: 6px solid #3c3835;
  &:hover {
    border-bottom: 6px double #fcb417;
  }
}

.footerLogoColumn {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  @media screen and (max-width: 750px) {
    padding-bottom: 20px;
  }

  img {
    height: 52px;
    max-width: 170px;
  }
}
.footer-line {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid #fab500;
  position: relative;
  margin-bottom: 20px;
}

.phone {
  color: #fafafa;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border: 0px solid #fff;
}

.phoneplus {
  color: #fafafa;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border: 0px solid #fff;
}

.copyright {
  color: #fafafa;
  text-align: center;
  padding-top: 20px;
  line-height: 25px;
  padding-bottom: 20px;
}
.privacy {
  color: #fafafa;
  text-align: center;
  a {
    color: #fab500;
    text-decoration: none;
  }
}

.footer-links-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 1100px;

  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 0 30px;
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
}

.city-links {
  text-align: center;
  margin: 15px 15px 0 15px;
  color: #fafafa;
}

.footer-city-link {
  text-decoration: none;
  color: #fab500;
}
