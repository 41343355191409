.CheckInPage-wrapper {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.Date-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding-top: 10px;
}

.Date-wrapper div:last-child {
  color: #00c08b;
}

.trip-leg {
  padding-top: 10px;
}

.trip-check-button button {
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 414px) {
  .trip-check-button button {
    min-width: 100px;
    text-align: center;
  }
}

.top-info {
  align-content: flex-start;
  position: relative;
}

.checkin-buttons {
  width: 50%;
  margin: 40px auto;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.separator-segment {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid #999;
  opacity: 50%;
  position: relative;
  margin-bottom: 20px;
}

.legTitle {
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
}
