.top-menu-profile-loyalty-data-wrapper {
  display: flex;
  padding: 10px;
}

.top-menu-profile-loyalty-item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top-menu-profile-loyalty-title {
  color: #696d6f;
  font-size: 12px;
  line-height: 15px;
}

.top-menu-profile-loyalty-content {
  font-size: 16px;
  line-height: 24px;
}
