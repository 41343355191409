.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100dvw;
  height: 100dvh;
  z-index: 1002;
  align-items: center;
  justify-content: center;
}

.modal {
  z-index: 20;
  border-radius: 20px;
  box-shadow: 0px 1px 23px 5px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  position: relative;
}

.login-dialog-btns-container {
  display: flex;
  gap: 0.5rem;
  border: 1px solid #fab500;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background-color: #fab5002b;
  line-height: 1.25;
  margin-bottom: 1rem;
  button {
    background-color: transparent;
    border: none;
    color: #fab500;
  }
}

.login-dialog-close-btn {
  top: 21px;
  right: 21px;
  position: absolute;
  z-index: 20;
  cursor: pointer;
  padding: 0.25rem;
  width: 24px;
  height: 24px;
}
