@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";

.footer {
  background: #f5f5f5;
  padding: 65px 0 64px 0;
  border-top: 1px solid #cdccc8;
  @include resp(726px) {
    padding: 0;
  }
  &__content {
    width: 88.8889%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include resp(726px) {
      flex-direction: column;
      margin-top: 64px;
      padding-bottom: 64px;
    }
    .leftSide {
      .logo {
        img {
          @include resp(726px) {
            max-width: 110px;
          }
        }
      }
    }
    .rowOne {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include resp(726px) {
        justify-content: center;
        margin-top: 44.02px;
      }
      .socialMediaLinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 120px;
        .mediaLink {
          cursor: pointer;
          svg {
            @include resp(726px) {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    .rowTwo {
      margin-top: 9px;
      @include resp(726px) {
        text-align: center;
        margin: 41px auto 0 auto;
        width: 206px;
      }
      .legal {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: right;

          color: #3c3835;
          @include resp(726px) {
            text-align: center;
          }
        }
      }
    }
    .linksWrapper {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @include resp(726px) {
        width: 256px;
        margin-top: 40px;
      }

      .linkContent {
        @include resp(726px) {
          text-align: center;
        }
        color: #fab500;
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #fab500;
          cursor: pointer;
        }
      }
    }
  }
}
