.Cities {
  background-color: #F5F5F5;
  padding: 60px 24px;
  display: flex;
  justify-content: center;

  .content-wrapper {
    max-width: 1100px; // Slightly wider for better spacing
    width: 100%;
  }

  /* Cities We Serve Section */
  .cities-we-serve-wrapper {
    margin-bottom: 40px;

    .section-title {
      font-family: "Source Sans 3", sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 24px;
    }

    .cities-grid {
      display: grid;
      grid-template-columns: repeat(3, 2fr);
      gap: 16px;
    }

    .city-card {
      background-color: white;
      border: 1px solid #ddd;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
      text-align: left;
      font-family: "Source Sans 3", sans-serif;
      height: 120px;
      text-decoration: none;  // Removes link styling
      color: #000;  // Default text color

    
      h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        color: #333;
      }

      p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #555;
      }

      .city-card:hover {
        background-color: #e9ecef;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  // Adds a subtle shadow on hover
      }
    }
  }

  /* Routes List Section */
  .routes-list-wrapper-1 {
    margin-top: 2rem;

    .routes-columns {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .routes-column {
      flex: 1;
      min-width: 300px; // Ensures columns fit on smaller screens
    }

    .routes-title {
      margin-bottom: 1rem;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .routes-list {
      list-style: none;
      padding: 0;

      .route-item {
        margin: 0.0rem 0;
      }
    }
  }
}

/* Responsive Design - Moved outside the main class */
@media screen and (max-width: 1024px) {
  .Cities {
    .cities-we-serve-wrapper {
      .cities-grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Cities {
    .cities-we-serve-wrapper {
      .cities-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    
    .routes-list-wrapper {
      .routes-columns {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Cities {
    .cities-we-serve-wrapper {
      .cities-grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

/* Tabs Wrapper */
.tabs-wrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 20px;

  .tab-button {
    border: none;
    background-color: transparent;
    color: #000;
    text-decoration: none;
    border-bottom: 4px solid #ddd;
    padding: 10px 20px;
    font-family: "Source Sans 3", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 23px;
    cursor: pointer;

    &.active {
      border-bottom: 4px solid #FAb500;
      color: #FAB500;
      font-weight: 600;
    }

    &:hover {
      background-color: #e0e0e0;
    }
  }
}

/* Existing classes remain unchanged */


.route-item {
  text-decoration: none;
  color: #000;
}

.route-item:hover {
  color: #fab500;  // Changes background color when hovered
}