.top-menu-profile-loyalty-wrapper .pagetitle {
  border-color: #fff;
  font-weight: normal;
  font-size: 18px;
}

.top-menu-profile-loyalty-wrapper .secondary button {
  text-align: left;
  padding: 0 20px;

  svg {
    right: 13px;
  }
}
