.subscribe-email {
  margin-top: 20px;

  .subscribe-email-input {
    margin-right: 20px;
    flex: 5;
  }

  @media screen and (max-width: 850px) {
    .subscribe-email-input {
      margin-right: 0px;
      padding-bottom: 24px;
    }
  }
}

.subscribe-container {
  display: flex;
  width: 100%;
  background-color: white;
  font-family: "Source Sans 3", sans-serif;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 22px;
  padding: 40px 0;

  & h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
  }

  & div {
    display: flex;
    flex-flow: column wrap;

    @media screen and (min-width: 850px) {
      flex-flow: row wrap;
    }
  }

  @media screen and (max-width: 768px) {
    width: -webkit-fill-available;
    margin: 1rem;
    text-align: -webkit-center;
  }

  @media screen and (max-width: 1180px) {
    margin-top: 50px;
    padding-top: 0;

    h2 {
      text-align: center;
    }

    #mc_embed_signup_scroll {
      flex-direction: column !important;
      flex-grow: unset !important;
      width: 80vw;
    }

    .mc-field-group {
      flex-grow: unset !important;
      margin: 0 !important;
      width: 100%;
    }

    #mc-embedded-subscribe {
      align-self: center;
      margin: 0 !important;
      margin-top: 10px !important;
      background: #3c3835 !important;
      color: #fab500 !important;
    }

    img {
      display: none;
    }

    img + div {
      width: 100vw !important;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    form {
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    width: 1100px;
    padding: 40px calc((100% - 1100px) / 2);
    box-sizing: content-box;
  }
}

.subscribe-inner-container {
  p {
    line-height: 1.25;
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 800px) {
    width: 500px;
  }
}
