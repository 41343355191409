@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";
.qna {
  &:last-of-type {
    .question {
      margin-bottom: 0;
    }
    .answerWrapper {
      margin-top: 32px;
      margin-bottom: 0;
      @include resp(650px) {
        margin-top: 16px;
      }
    }
  }
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 12px 27px 12px 30px;
    cursor: pointer;
    margin-bottom: 2rem;

    @include resp(650px) {
      display: grid;
      grid-template-columns: auto auto;
      align-items: start;
      margin-bottom: 16px;
      padding: 14px 21.22px 14px 16px;
    }
    .questionTitle {
      font-style: italic;
      font-weight: 400;
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 0px;
      color: $color-white;
      @include resp(650px) {
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
      }
    }
    .plusAndMinusIcons {
      min-width: 15px;
      stroke: $color-white;
      stroke-width: 2;

      @include resp(650px) {
        margin-top: 7px;
        width: 12px;
        height: 12px;
        justify-self: center;
      }
    }
  }
  .blue {
    background-color: $aa-blue;
  }
  .darkBlue {
    background-color: $united-blue-dark;
  }

  .airCanadaRed {
    background-color: #000000;
  }

  .answerWrapper {
    margin-bottom: 32px;
    @include resp(650px) {
      margin-bottom: 16px;
    }
    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: $color-landline-gray;
      @include resp(650px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    a {
      color: $aa-blue;
      text-decoration: underline;
    }
  }
  video {
    width: 100%;
    margin-bottom: 24px;
    @include resp(650px) {
      margin-bottom: 16px;
    }
  }
}
