.tab-layout-container {
  width: 100%;
  .tab-content {
    padding-top: 24px;
  }
  .tabs-navigation {
    width: 100%;
    display: flex;
    background-color: #fff;
    padding: 0 calc((100% - 1100px) / 2);
    @media screen and (max-width: 500px) {
      .tab-container {
        flex: 1;
      }
    }
    .tab-container {
      width: 25%;
      position: relative;
      color: #999999;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      flex-shrink: 0;
      font-size: 18px;
      line-height: 150%;
      cursor: pointer;
    }
    .tab-container:hover {
      color: #fab500;
    }
    .activeTab {
      font-weight: 600;
      color: #fab500;
      opacity: 1;
      font-size: 18px;
    }
  }
}
