@import "../../styles/mixin.scss";

.B2BHowItWorksIconAndDes {
  display: flex;
  align-items: center;
  gap: 24px;
  @include resp(650px) {
    gap: 16px;
  }
  .B2BHowItWorksIcon {
    @include resp(1100px) {
      width: 7.143%;
    }
  }
  .B2BHowItWorksDescription {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
    @include resp(1100px) {
      font-size: 20px;
      line-height: 25px;
    }
    @include resp(650px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
