@import "./constants/colors.scss";
@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Source Sans 3";
  src: url("./fonts/SourceSans3-VariableFont_wght.ttf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-scroll-behaviour: smooth;
}
body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Source Sans 3";
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $darkLandline !important;
  width: 100%;
  overflow-x: hidden;

  &.overflowHidden {
    overflow-y: hidden;
  }
}
html,
body {
  height: 100%;
}

.Login--Buttons {
  width: 400px;
  align-content: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.Profile--create-account-button {
  width: 100%;
  margin: 20px auto;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}
.Login--Buttons button {
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.Login--remember-me-row {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: none;
    color: $gray090;
    &:hover {
      color: $primaryLandline;
    }
  }
  p {
    text-decoration: none;
    color: $gray090;
    cursor: pointer;
    &:hover {
      color: $primaryLandline;
    }
  }
}
.SignUp--top {
  width: 100%;
  padding: 20px;
  @media screen and (min-width: 1200px) {
    width: 1100px;
    margin: 0 auto;
  }
}
.Greyout {
  opacity: 0.5;
  pointer-events: none;
  background-color: $dirtyWhite;
}
a.button,
button.button,
.button-default button,
*[class*="button"] {
  font-family: Source Sans 3 !important;
}
