@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";

a {
  text-decoration: none;
}

.whereToFindUs img {
  width: 100%;
}

.american {
  @include resp(650px) {
    background-color: $color-landline-light-gray;
  }
  .hero {
    display: flex;
    background-color: $aa-blue;
    @include resp(1100px) {
      background-image: linear-gradient(
          180deg,
          rgba(44, 44, 44, 0.726) 32.81%,
          rgba(0, 0, 0, 0) 87.81%
        ),
        url("../../../media/american-airlines-bus.png");
      background-size: cover;
      background-position: left center;
      height: 613px;
    }

    @include resp(1100px) {
      background-color: unset;
      flex-direction: column;
    }
    .heroContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding-left: 5.556%;
      padding-right: 3.889%;

      @include respUp(2000px) {
        padding-left: 12.476%;
      }
      @include resp(1100px) {
        width: 100%;
        padding: 24px 6.667% 0 6.667%;
      }
    }
    .heroTitle {
      font-weight: 900;
      font-size: 64px;
      line-height: 96px;
      color: $color-white;
      margin-bottom: 2rem;
      @include resp(1100px) {
        font-size: 32px;
        line-height: 48px;
      }
      @include resp(650px) {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 1rem;
      }
    }
    .heroText {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: $color-white;
      margin-bottom: 2rem;
      @include resp(1100px) {
        font-size: 18px;
        line-height: 24px;
      }
      @include resp(650px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .bookNowBtn {
      display: flex;
      justify-content: center;
      max-width: 240px;
      min-width: 200px;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      border-radius: 8px;
      background-color: $color-white;
      @include resp(1100px) {
        max-width: 24%;
        min-width: 140px;
        border: 1px solid $aa-blue;
        font-size: 14px;
        line-height: 24px;
        @include resp(650px) {
          margin-top: 22px;
        }
      }

      .bookNowLink {
        color: $color-landline-gray;
        display: inline-block;
        width: 100%;
        padding-top: 14px;
        padding-bottom: 14px;
        text-align: center;
        &:hover {
          color: $aa-blue;
        }
        @include resp(1100px) {
          padding: 6px;
          text-align: center;
        }
      }
    }
    //style gatsby-image-wrapper
    .heroImageWrapper {
      width: 50%;
      background: url("../../../media/american-airlines-bus.png");

      background-color: transparent;
      background-size: cover;
      background-position: center center;
      height: 640px;
      border-top-left-radius: 500px;
      border-bottom-left-radius: 500px;

      @include respUp(2000px) {
        height: 900px;
      }
      @include resp(1100px) {
        display: none;
      }
    }
  }
  .howItWorks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 136px auto;

    @include resp(650px) {
      margin-bottom: 0;
      padding: 64px 0;
      background-color: $color-white;
      margin: 0;
    }
    .howItWorksTitle {
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 8.375rem;
      @include resp(650px) {
        font-size: 30px;
        line-height: 24px;
        margin-bottom: 64px;
      }
    }
    .icons {
      width: 80.5556%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      @include respUp(2000px) {
        max-width: 50%;
      }
      @include resp(1100px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      @include resp(650px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
        width: 86.667%;
        gap: 64px;
      }
    }
  }

  .aaLogoWrapper {
    display: flex;
    justify-content: center;
    margin: 136px 0 136px 0;
    @include resp(650px) {
      margin: 64px 0 64px 0;
    }
    a {
      @include resp(650px) {
        display: flex;
        justify-content: center;
      }
      .aaLogo {
        @include resp(650px) {
          width: 63.338%;
        }
      }
    }
  }
  .whereToFindUs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 20px;
    width: 80.556%;
    margin: 136px auto 0 auto;
    text-align: center;
    @include respUp(2000px) {
      max-width: 50%;
    }
    @include resp(1100px) {
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      gap: 64.57px;
    }
    @include resp(650px) {
      margin-top: 64px;
      width: 100%;
      padding: 0 24px;
      gap: 64.57px;
    }

    .whereToFindUsContent {
      .whereToFindUsTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 2.5rem;
        @include resp(650px) {
          text-align: center;
          font-size: 28px;
          line-height: 40px;
        }
      }
      .whereToFindUsText {
        font-size: 24px;
        line-height: 32px;

        @include resp(650px) {
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
        }
      }
      .whereToFindUsBold {
        font-weight: 600;
        margin-top: 32px;

        @include resp(650px) {
          margin-top: 26px;
        }
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}
