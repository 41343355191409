.contact-page-container {
  font-family: "Source Sans 3";
}

.contact-page-card {
  display: flex;
  align-items: center;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1.2rem;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  max-width: 15rem;
  min-width: 10rem;
  @media screen and (max-width: 768px) {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}

.contact-page-content {
  max-width: 1100px;
  margin: 0 auto;
}

.contact-page-cards-container {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem;
  padding-top: 0;
  margin-bottom: 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    
  }
}

.contact-page-card-info {
  max-width: 180px;
  font-size: .8rem;
  line-height: 1.2;
  color: #1a1a1a;
}


.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center !important;
  justify-content: center;
  background-color: white;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  font-family: "Source Sans 3", sans-serif;
  height: 120px;
  text-decoration: none;  // Removes default link styling
  color: rgba(0, 0, 0, 0.05);  // Default text color
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e9ecef;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  // Hover effect
    transform: translateY(-2px);  // Slight lift effect
  }
}

.contact-card-icon {
  font-size: .8rem;
  color: #1a1a1a;

}

.contact-card-title {
  font-size: 1rem;
  text-align: center !important;
  color: #1a1a1a;
  line-height: 1rem;
  font-weight: bold;
  margin-top: 8px;
}
