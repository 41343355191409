.checkin-modal-info-container {
  .depart {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .return {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    flex: 1;
  }
  .legconnection-container {
    margin-top: 56px;
  }
  .city-name-container {
    margin-top: 48px;
    width: 100%;
    display: flex;
    svg {
      fill: #cdccc8;
      margin-top: 10px;
    }
  }
  .datetime-container {
    display: flex;
  }
  .text-below-action {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.clickable {
      cursor: pointer;
      width: fit-content;
      margin-left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
  .primary {
    svg {
      fill: #fab500 !important;
    }
    &:hover {
      svg {
        fill: #3c3835 !important;
      }
    }
  }
  .regular {
    svg {
      fill: #3c3835 !important;
    }
    &:hover {
      svg {
        fill: #fab500 !important;
      }
    }
  }
}
