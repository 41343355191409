// VARIABLES
@use "sass:color";

$color-primary: #1a1a1a;
$color-accent: #ffb300; // For markers and active tab; adjust to #FAB500 if needed.
$color-light: #f8f9fa;
$spacing-unit: 1.5rem;


// FULL-WIDTH HEADING
.full-width-heading {
  background: $color-primary;
  color: white;
  padding: 4rem 1rem;
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    font-size: 2.5rem;
    max-width: 1200px;
    margin: 0 auto;
    line-height: 1.2;
  }
}


// HOW-IT-WORKS SECTION
.how-it-works {
  font-family: 'Source Sans 3', sans-serif;
  line-height: 1.6;

  .how-intro {
    text-align: center;
    padding: $spacing-unit * 3 1rem;
    max-width: 800px;
    margin: 0 auto;

    h1 {
      font-size: 2.25rem;
      color: $color-primary;
      margin-bottom: $spacing-unit;
      line-height: 1.2;
    }

    p {
      font-size: 1.125rem;
      color: color.adjust($color-primary, $lightness: 30%);
    }
  }

  .how-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: $spacing-unit;
    padding: $spacing-unit * 2 1rem;
    max-width: 1200px;
    margin: 0 auto;

    .step-card {
      padding: $spacing-unit;
      text-align: center;
      background: $color-light;
      border-radius: 8px;
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-5px);
      }

      img {
        height: 80px;
        margin-bottom: $spacing-unit;
      }

      h3 {
        font-size: 1.25rem;
        color: $color-primary;
        margin-bottom: $spacing-unit / 2;
      }

      p {
        color: color.adjust($color-primary, $lightness: 30%);
        font-size: 0.95rem;
      }
    }
  }

  .how-routes {
    padding: $spacing-unit * 2 1rem;
    max-width: 1200px;
    margin: 0 auto;

    // Tabs (we use the tabs-wrapper defined below)
    .tabs-wrapper {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 20px;
    }
  }

  .how-cta {
    background: $color-primary;
    color: white;
    padding: $spacing-unit * 4 1rem;
    margin: $spacing-unit * 2 0;

    .cta-content {
      max-width: 800px;
      margin: 0 auto;
      text-align: center;

      h2 {
        font-size: 2rem;
        margin-bottom: $spacing-unit;
      }

      p {
        margin-bottom: $spacing-unit * 2;
        opacity: 0.9;
      }

      .cta-button {
        display: inline-block;
        padding: 1rem 2.5rem;
        background: $color-accent;
        color: white;
        text-decoration: none;
        border-radius: 50px;
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .how-faq {
    text-align: center;
    padding: $spacing-unit * 3 1rem;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 1.5rem;
      margin-bottom: $spacing-unit;
      color: $color-primary;
    }

    a {
      color: $color-accent;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


/* --- MAP CONTAINER FIXES --- */

/* Force the SVG inside to always be 1000x600 pixels */
.map-container svg {
  width: 1000px;
  height: 600px;
  display: block;
  margin: 0 auto;        /* center the SVG horizontally within the container */
}

/* --- MAP CONTAINER --- */
.map-container {
  position: relative;
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling if viewport is narrower than 1000px */
  margin: 2rem auto;
}


@media (max-width: 768px) {
  .map-container {
    width: 90vw;
    max-width: 90vw;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .fixed-map {
    width: 1000px;
    height: 600px;
    margin: 0;
    /* Shift map left by 100px to reveal right portion */
    transform: translateX(-200px);
  }
}
/* Fixed-size map wrapper to ensure the SVG always stays 1000x600px */
.fixed-map {
  width: 1000px;
  height: 600px;
  margin: 0 auto;
}

/* Ensure the SVG inside .fixed-map retains its dimensions */
.fixed-map svg {
  width: 1000px;
  height: 600px;
  display: block;
}


/* Routes List Wrapper (overlay on desktop, below the map on mobile) */
.routes-list-wrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 300px;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* On mobile, reposition the routes list below the map */
@media (max-width: 768px) {
  .routes-list-wrapper {
    position: relative;
    width: 100%;
    right: 0rem !important;
    margin-top: 1rem;
    box-shadow: none !important;
    padding: none !important;
  }
}


// Routes List Cards
.routes-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 1rem;
}

.route-item {
  display: block;
  background: $color-light;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
  text-decoration: none;
  color: $color-primary;
  font-family: "Source Sans 3", sans-serif !important;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: $color-accent;
    color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}


// TABS (styled like the Cities section tabs)
.tabs-wrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 20px;

  .tab-button {
    border: none;
    background-color: transparent;
    color: #000;
    text-decoration: none;
    border-bottom: 4px solid #ddd;
    padding: 10px 20px;
    font-family: "Source Sans 3", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 23px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: #e0e0e0;
    }
    &.active {
      border-bottom: 4px solid #FAB500;
      color: #FAB500;
      font-weight: 600;
    }
  }
}


// ROUTE MAP CONTAINER (if used as an overlay container)
.route-map-container {
  position: relative;
  max-width: 1200px;
  margin: 2rem auto;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  /* Remove extra overflow-x styling here since .map-container handles it */
}


// MOBILE RESPONSIVE ADJUSTMENTS (for other sections)
@media (max-width: 768px) {
  .full-width-heading h1 {
    font-size: 2rem;
    padding: 0 1rem;
  }
  
  .how-it-works {
    .how-intro {
      padding: $spacing-unit * 2 1rem;
      h1 {
        font-size: 1.75rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .how-steps {
      grid-template-columns: 1fr;
      padding: $spacing-unit 1rem;
    }
    .how-routes {
      .tabs-wrapper {
        flex-direction: column;
        .tab-button {
          width: 100%;
        }
      }
    }
    .how-cta {
      padding: $spacing-unit * 2 1rem;
      .cta-content {
        h2 {
          font-size: 1.5rem;
        }
      }
    }
  }
}


.amenities-section {
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}

.intro-text {
  text-align: left;
  max-width: 1100px;
  margin: 0 auto 0 auto;
  font-size: 1.125rem;
  color: #333;
  line-height: 1.4;
  padding: 0 2rem;

}

.amenities-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem;
}

.amenity-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon1-wrapper {
  background: transparent ;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.amenity-title {
  max-width: 180px;
  font-size: 1.125rem;
  line-height: 1.2;
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .amenities-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .amenities-grid.two-columns {
    grid-template-columns: repeat(1, 1fr);
  }

  
}

@media (max-width: 480px) {
  .amenities-grid {
    grid-template-columns: 1fr;
  }

  .amenities-grid.two-columns {
    grid-template-columns: 1fr;
  }
}

.bp p {
  text-align: left;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 1.125rem;
  color: #333;
  line-height: 1.4;
  margin-bottom: 1rem;
  padding: 0 2rem;
}


.airline-logos {
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}

.amenities-section {
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}



.airline-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem;
}


@media (max-width: 768px) {
  .airline-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .airline-grid {
    grid-template-columns: 1fr;
  }
}


.airline-card {
  background: #1a1a1a;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.2s ease;
}

.airline-card:hover {
  transform: scale(1.05);
}

.airline-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 80px;
}

.airline-logo {
  max-width: 100%;
  max-height: 60px;
  object-fit: contain;
}

.external-link-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  font-size: 1rem;
  opacity: 0.8;
}

.amenities-grid.two-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust min size for better fit */
  gap: 1.5rem;
  max-width: 1100px;
  margin: 0 auto;
  padding: 1.5rem;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .amenities-grid.two-columns {
    grid-template-columns: 1fr; /* Stack items in a single column */
    gap: 1rem;
    padding: 1rem;
  }
}


.routes1-title{
  font-weight: 600;
  text-align: center;
  margin-bottom: 0rem !important;
}

/* Full-width Heading with Blurred Background */
.full-width-heading {
  position: relative;
  background: url("./shuttleinterior.jpg") center/cover no-repeat; /* Use your imported image */
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin-bottom: 2rem;
  min-height: 200px; /* Ensure it has enough height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Darkened & Blurred Overlay */
.full-width-heading::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  backdrop-filter: blur(0px); /* Blurred effect */
  z-index: 1;
}

/* Ensure content stays visible above the overlay */
.full-width-heading h1 {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 2.8rem;
  margin-bottom: 1rem;
  z-index: 2;
  position: relative;
}

.cta-button {
  font-family: "Source Sans 3", sans-serif !important;
  display: inline-block;
  background: #ffb300;
  color: black;
  padding: 0.75rem 2.5rem;
  border-radius: 100px;
  text-decoration: none;
  font-weight: 600;
  transition: background 0.2s ease, transform 0.2s ease;
  position: relative;
  z-index: 2;
}

.cta-button:hover {
  background: #e09c00;
  transform: scale(1.05); /* Slight scale on hover for better UX */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .full-width-heading {
    padding: 3rem 1rem;
    min-height: 250px;
  }
  
  .full-width-heading h1 {
    font-size: 2rem;
  }

  .cta-button {
    padding: 0.6rem 2rem;
  }
}


/* Icon Section */
.icon1-section {
  text-align: center;
  padding: 1rem;
  background: white;
}


.icon1-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: .5rem;
  justify-content: center;
  padding: .5rem;
}


@media (max-width: 768px) {
  .icon1-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .icon1-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.icon1-card {
  background-color: white;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  font-family: "Source Sans 3", sans-serif;
  height: 120px;
  text-decoration: none;  // Removes link styling
  color:$color-primary;  // Default text color


  
}

.icon1-card:hover {
  background-color: #e9ecef;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  // Adds a subtle shadow on hover
}

.icon1-wrapper {
  font-size: 2rem;
}

.icon1-title {
  font-size: 1rem;
  color: $color-primary;
  line-height: 1rem;
  font-weight: bold;
}

/* FAQ Section */
.faq1-section {
  background: #ffb300;
  color: black;
  padding: 2rem 1rem;
  text-align: center !important;
}

.faq1-content {
  max-width: 800px;
  margin: 0 auto;
}

.faq1-content h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.faq1-content a {
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.faq1-content p {
  font-size: 1rem;
  margin-top: 0.5rem;
  text-align: center !important;
}


