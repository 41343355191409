@media (min-width: 1200px) {

    .schedule-page {
        margin-top: 50px;
    }

    .charters-page-module {
        display: flex;
        padding: 20px 40px;
        margin-bottom: 30px;
    }

    .charters-page-module img {
        width: 400px;
        min-width: 400px;
        border-radius: 10px;
        max-height: 275px;
    }

    .charters-page-module-panel {
        padding: 10px 20px;
    }

    .charters-page-module-panel h4 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .charters-page-module-panel p {
        text-align: left;
        font-size: 1.05em;
        line-height: 1.3em;
        margin-bottom: 10px;
    }

    .charters-page-main-bullets {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        padding-left: 20px;
    }

    .charters-page-main-bullets li {
        line-height: 1em;
    }

    .charter-page-testimonials {
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
    }

    .charter-page-testimonials img {
        width: 30%;
        border-radius: 10px;
    }

    .charter-service-main-headline {
        padding: 0px 40px;
        line-height: 1.2em;
    }

    .charters-page-quote {
        background-color: #FFB404;
        border-radius: 10px;
        font-weight: 600;
        font-size: 22px;
        margin: 0% 40%;
        border: none;
        padding: 10px;
    }
}

@media (max-width: 1200px) {

    .schedule-page {
        margin-top: 20px;
    }

    .charters-page-module {
        padding: 20px;
        margin-bottom: 30px;
    }

    .charters-page-module img {
        width: 100%;
        border-radius: 10px;
        max-height: 275px;
    }

    .charters-page-module-panel {
        padding: 10px 20px;
    }

    .charters-page-module-panel h4 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .charters-page-module-panel p {
        text-align: left;
        font-size: 1.05em;
        line-height: 1.3em;
        margin-bottom: 10px;
    }

    .charters-page-main-bullets {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        margin-left: 20px;
    }

    .charters-page-main-bullets li {
        line-height: 1em;
    }

    .charter-page-testimonials {
        padding: 0px 20px;
    }

    .charter-page-testimonials img {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .charter-service-main-headline {
        padding: 0px 20px;
        line-height: 1.2em;
    }

    .charters-page-quote {
        background-color: #FFB404;
        border-radius: 10px;
        font-weight: 600;
        font-size: 22px;
        margin-left: 27.5%;
        border: none;
        padding: 10px;
    }
}