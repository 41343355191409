@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";

.hero {
  display: grid;
  grid-template-columns: auto 46.667%;
  background-color: $united-blue-dark;
  max-height: 640px;

  @include resp(650px) {
    grid-template-columns: 1fr;
    background-image: url("https://content.landline.com/wp-content/uploads/2025/01/unitedBusHeroMobile.png");
    background-size: cover;
    background-position: left center;
    height: 613px;
  }

  .heroContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 3.125%;
    padding-left: 10.417%;
    @include resp(650px) {
      justify-content: flex-start;
      padding: 6.667%;
    }
  }
  .heroTitle {
    font-weight: 900;
    font-size: 64px;
    line-height: 96px;
    color: $color-white;
    margin-bottom: 24px;
    @include resp(1420px) {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 16px;
    }

    @include resp(700px) {
      font-size: 20px;
      line-height: 20px;
    }
  }
  .heroText {
    font-weight: 200;
    font-size: 20px;
    line-height: 32px;
    color: $color-white;
    margin-bottom: 24px;
    @include resp(1100px) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 12px;
    }
    @include resp(650px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .emphasizedText {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
    @include resp(1100px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    @include resp(650px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 24px;
    }
  }
  .bookNowBtn {
    display: flex;
    justify-content: center;
    max-width: 240px;
    min-width: 200px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    border-radius: 8px;
    background-color: $color-white;
    @include resp(1100px) {
      max-width: 24%;
      min-width: 140px;
      border: 1px solid $united-blue-light;
      font-size: 14px;
      line-height: 24px;
    }

    .bookNowLink {
      color: $united-blue-dark;
      display: inline-block;
      width: 100%;
      padding-top: 14px;
      padding-bottom: 14px;
      text-align: center;
      &:hover {
        color: $united-blue-light;
      }
      @include resp(1100px) {
        padding: 6px;
        text-align: center;
      }
    }
  }
  .heroImageWrapper {
    display: flex;
    justify-content: flex-end;
    @include resp(650px) {
      display: none;
    }
    .heroImage {
      max-width: 100%;
      @include respUp(2000px) {
        justify-self: end;
      }
    }
  }
}
.faqSection {
  padding-top: 180px;
  padding-bottom: 32px;
  background-color: $color-light-gray;
  @include resp(650px) {
    padding-top: 8.889%;
    padding-bottom: 4.445%;
  }
  .faqContent {
    width: 80.556%;
    margin: 0 auto;
    @include respUp(2000px) {
      width: 50%;
    }
    @include resp(650px) {
      width: 86.667%;
    }
  }
  .faqTitle {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 32px;
    @include resp(1100px) {
      font-size: 30px;
      line-height: 36px;
    }
    @include resp(650px) {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
}
