@media (min-width: 1200px) {
    .aircanada-terms-container {
        width: 1000px;
        margin: auto;
        padding-top: 100px !important;
    }
}

.aircanada-terms-container a {
    color: #fab500;
}

.aircanada-terms-container h4 {
    margin-top: 30px;
    margin-bottom: 15px;
}

.wpWrapper {
    color: #000;
    padding: 40px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .wpWrapper {
        padding: 20px
    }
}

.wpWrapper .wpContent {
    margin: 0 auto;
    max-width: 1060px
}

.wpWrapper .wpContent h1 {
    color: #3c3835;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 20px
}

.wpWrapper .wpContent h2 {
    color: #3c3835;
    display: inline-block;
    font-size: 31px;
    font-weight: 600;
    line-height: 1.4;
    margin: 20px 0 10px
}

@media screen and (max-width: 768px) {
    .wpWrapper .wpContent h2 {
        font-size: 30px
    }
}

.wpWrapper .wpContent h2:first-child {
    margin-top: 0
}

.wpWrapper .wpContent h3 {
    color: #3c3835;
    display: inline-block;
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px
}

@media screen and (max-width: 768px) {
    .wpWrapper .wpContent h3 {
        font-size: 24px !important
    }
}

.wpWrapper .wpContent h3 b {
    font-weight: 600
}

.wpWrapper .wpContent h4 {
    font-size: 22px;
    line-height: 1.3
}

@media screen and (max-width: 475px) {
    .wpWrapper .wpContent h4 {
        font-size: 18px !important
    }
}

.wpWrapper .wpContent h6 {
    font-weight: 600;
    margin: 10px 0
}

.wpWrapper .wpContent p {
    color: #000;
    font-weight: 400;
    line-height: 1.45;
    margin-bottom: 10px
}

.wpWrapper .wpContent p b {
    font-weight: 900
}

.wpWrapper .wpContent li {
    color: #000;
    font-size: 18px;
    list-style-image: none;
    margin-left: 40px;
}

@media screen and (max-width: 768px) {
    .wpWrapper .wpContent li {
        font-size: 14px !important;
        margin-left: 20px;
    }
}

.wpWrapper .wpContent a:hover {
    text-decoration: underline
}

.wpWrapper .wpContent .main-title {
    margin-top: 0
}

.wpWrapper .wpContent .subtitle {
    margin-bottom: 10px
}

.wpWrapper .wpContent .wp-block-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0
}

@media screen and (max-width: 475px) {
    .wpWrapper .wpContent .wp-block-buttons {
        margin: 0
    }
}

.wpWrapper .wpContent .wp-block-buttons .wp-block-button {
    border-radius: 4px;
    display: flex
}

.wpWrapper .wpContent .wp-block-buttons .wp-block-button a {
    background-color: #fab500;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    padding: 12px 24px
}

.wpWrapper .wpContent .wp-block-buttons .wp-block-button a:hover {
    background-color: #faab00 !important;
    text-decoration: none;
    transition: .1s
}

.wpWrapper .wpContent .wp-block-separator {
    border: none;
    border-top: 1px solid #ccc
}