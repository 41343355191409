.top-menu-hamburger-wrapper {
  @media screen and (min-width: 1200px) {
    display: none;
  }

  .book-now-button {
    font-weight: 600;
    color: #000 !important;
    padding: 0px 40px !important;
    background-color: #fab500;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    width: 150px;
    height: 32px;
    margin: 30px auto 0px auto !important;
    border-radius: 2px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }
}

.top-menu-hamburger-icon {
  width: 18px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    display: none;
  }
}

.hamburger-links {
  left: 0;
  top: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 10;

  background-color: #000;
  font-family: "Source Sans 3";
  .top-menu-hamburger-link a {
    display: flex;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    margin-left: 30px;
    margin-right: 30px;
    padding: 10px 0px;
    justify-content: flex-start;
    align-content: flex-start;
    align-self: flex-start;
    &:hover {
      color: white;
    }
  }
  .Separator--menu {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #493400;
  }
  .close-button {
    cursor: pointer;
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 50px;
    right: 0;
    left: 0;
    padding-top: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.logo-wrapper {
  margin: 15px 0 15px 0;
  height: 55px;
  img {
    display: block;
    margin: auto;
    height: 52px;
  }
  .logo {
    margin-top: 10px;
  }
}

.hamburger-icon {
  width: 20px !important;
}
