@media (min-width: 1200px) {

    .schedule-page {
        margin-top: 50px;
    }

    .charters-page-quote {
        background-color: #FFB404;
        border-radius: 10px;
        font-weight: 600;
        font-size: 22px;
        margin: 0% 40%;
        border: none;
        padding: 10px;
    }

    .penguin-perks-action {
        width: 100%;
        text-align: center;
    }

    .penguin-perks-signup {
        background-color: #FFB404;
        border-radius: 10px;
        font-weight: 600;
        font-size: 22px;
        border: none;
        padding: 10px;
    }

    .penguin-perks-panel {
        display: flex;
        padding: 20px 20px;
    }

    .how-it-works-element h4 {
        text-align: center;
        font-size: 20px;
    }
    .how-it-works-element p {
        margin: 0% 10%;
        line-height: 20px;
        text-align: center;
    }

    .how-it-works-element img {
        width: 100px;
    }

    .how-it-works-element {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
    }

    .rider-tiers-element {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
    }

    .rider-tiers-element img {
        width: 200px;
    }

    .rider-tiers-element h4 {
        padding-bottom: 20px;
        font-size: 22px;
        width: 65%;
        margin: auto;
        text-align: center;
    }

    .rider-tiers-element ul {
        margin-left: 20px;
        width: 65%;
        margin: auto;
    }

    .rider-tiers-element li {
        margin-top: 0px;
        line-height: 1.25rem;
    }

    .penguin-faq-panel {
        padding: 20px;
        font-size: 1.1em;
    }

}

@media (max-width: 1200px) {

    .schedule-page {
        margin-top: 20px;
    }

    .charters-page-quote {
        background-color: #FFB404;
        border-radius: 10px;
        font-weight: 600;
        font-size: 22px;
        margin-left: 27.5%;
        border: none;
        padding: 10px;
    }

    .penguin-perks-action {
        width: 100%;
        text-align: center;
    }

    .penguin-perks-signup {
        background-color: #FFB404;
        border-radius: 10px;
        font-weight: 600;
        font-size: 22px;
        border: none;
        padding: 10px;
    }

    .penguin-perks-panel {
        display: flex;
        padding: 20px 20px;
        flex-direction: column;
    }

    .how-it-works-element h4 {
        text-align: center;
        font-size: 20px;
    }
    .how-it-works-element p {
        margin: 0% 10%;
        line-height: 20px;
        text-align: center;
    }

    .how-it-works-element img {
        width: 100px;
    }

    .how-it-works-element {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .rider-tiers-element {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .rider-tiers-element img {
        width: 180px;
    }

    .rider-tiers-element h4 {
        padding-bottom: 20px;
        font-size: 22px;
        width: 65%;
        margin: auto;
        text-align: center;
    }

    .rider-tiers-element ul {
        margin-left: 20px;
        width: 65%;
        margin: auto;
    }

    .rider-tiers-element li {
        margin-top: 0px;
        line-height: 1.25rem;
    }

    .penguin-faq-panel {
        padding: 20px;
        font-size: 1.1em;
    }
}