.door2-door-wrapper {
}

.door2-door-text {
  font-size: 16px;
  line-height: 27px;
  margin-top: 20px;
}

.door2-door-address {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  border-bottom: 1px solid #cdccc8;
  display: flex;
  padding: 10px 0;
  margin: 15px 0;
}
