@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";

a {
  text-decoration: none;
}

.hero {
  display: flex;
  grid-template-columns: auto 46.667%;
  background-color: black;
  height: 30dvw;
  background-position: right center;
  background-image: url("https://content.landline.com/wp-content/uploads/2025/01/aclandingdesktop.jpg");
  background-repeat: no-repeat;
  background-size: 90dvw;

  @include resp(650px) {
    grid-template-columns: 1fr;
    background-image: url("https://content.landline.com/wp-content/uploads/2025/01/acbannermobile.png");
    background-size: contain;
    background-position: bottom center;
    height: 85dvw;
  }

  .heroContent {
    display: flex;
    width:51dvw;
    flex-direction: column;
    justify-content: center;
    padding-right: 3.125%;
    padding-left: 5%;
    @include resp(650px) {
      justify-content: flex-start;
      padding: 6.667%;
      width:90dvw;
    }
  }
  .heroTitle {
    font-weight: 900;
    font-size: 2.5dvw;
    line-height: 3.25dvw;
    color: $color-white;
    margin-bottom: 24px;

    @include resp(650px) {
      font-size: 4.5dvw;
      line-height: 6dvw;
      margin-bottom: 3dvw;
    }
  }
  .heroText {
    font-weight: 200;
    font-size: 1.65dvw;
    width: 30dvw;
    line-height: 2.5dvw;
    color: $color-white;
    margin-bottom: 24px;

    @include resp(650px) {
      font-weight: 400;
      font-size: 3.25dvw;
      line-height: 4dvw;
      width:100%;
      margin-bottom: 3dvw;
    }
  }
  .emphasizedText {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
    @include resp(1100px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    @include resp(650px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 24px;
    }
  }
  .bookNowBtn {
    display: flex;
    justify-content: center;
    margin-top: 2dvw;
    width: 13dvw;
    font-weight: 600;
    font-size: 1.35dvw;
    line-height: 1.5dvw;
    border-radius: 0.5dvw;
    background-color: #f01428;

    @include resp(650px) {
      margin-top: 10px;
      font-size: 3dvw;
      width: 25dvw;
      padding: 2dvw;
      border-radius: 2dvw;
    }

    .bookNowLink {
      color: white;
      display: inline-block;
      width: 100%;
      padding: 1dvw 0dvw;
      text-align: center;
      &:hover {
        color: white;
      }
    }
  }
  .heroImageWrapper {
    display: flex;
    justify-content: flex-end;
    @include resp(650px) {
      display: none;
    }
    .heroImage {
      max-width: 100%;
      @include respUp(2000px) {
        justify-self: end;
      }
    }
  }
}
.faqSection {
  padding-top: 0px;
  padding-bottom: 32px;
  background-color: white;
  @include resp(650px) {
    padding-top: 2%;
    padding-bottom: 4.445%;
  }
  .faqContent {
    width: 80.556%;
    margin: 0 auto;
    @include respUp(2000px) {
      width: 50%;
    }
    @include resp(650px) {
      width: 86.667%;
    }
  }
  .faqTitle {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 32px;
    @include resp(1100px) {
      font-size: 30px;
      line-height: 36px;
    }
    @include resp(650px) {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
}
