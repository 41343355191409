.B2BNavigation {
    display: grid;
    border-top: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;

    @media only screen and (orientation: landscape) {
        grid-template-columns: 25% 25% 25% 25%;
        height: 10dvh;
    }

    @media only screen and (orientation: portrait) {
        grid-template-columns: 50% 50%;
        height: 10dvh;
    }
}

.B2BNavigationItem {
    border-right: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

    @media only screen and (orientation: landscape) {
        width: 25dvw;
    }

    @media only screen and (orientation: portrait) {
        width: 50dvw;
    }

    a {
        color: black;
    }

    .B2BNavigationItem:hover {
        background-color: #d8caa6;
    }

    a:hover {
        color: #fab500;
        transition: all 0.5s;
    }
}