.payment-response-page {
  background-color: #f5f5f5;
}

.faux-breadcrumbs {
  background: #4d4d4d;
  padding-left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
  height: 45px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 414px) {
    background: #ebebeb;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;

    color: #000000;
  }
}

.door2doorCointainer {
  width: 100%;
  padding: 20px 0;
}

.door2DoorInfo {
  position: relative;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  border: 1px solid #3c3835;
  border-radius: 6px;
  padding: 4px 80px;
  align-items: center;
  @media (max-width: 600px) {
    padding: 0 3%;
  }
}

.thankYouTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-top: 55px;
  justify-content: center;

  @media screen and (max-width: 414px) {
    margin-top: 20px;
    text-align: center;
  }
}

.reference,
.information {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.information {
  margin-top: 10px;
  margin-bottom: 20px;
  @media screen and (max-width: 414px) {
    display: block;
    text-align: center;
  }
}

.close-button-container {
  width: 50%;
  margin: 20px auto;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.close-button-container-loylaty {
  text-align: center;
  margin: 20px 0;
  a {
    text-decoration: none;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 6px;
    background: #fab500;
    color: #3c3835;
    width: 80%;
    transition: all 0.3s ease;

    &:hover {
      background: #3c3835;
      color: #fab500;
    }
  }
}

.payment-prices-wrapper {
  margin-top: 20px;
}

.inline-payment-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 65px;
  margin-top: 60px;

  .payment-prices-wrapper,
  .loyalty-reward-wrapper {
    width: 100%;

    .pagetitle {
      margin: 0 !important;
    }
  }

  .loyalty-reward-wrapper .pagetitle {
    text-transform: uppercase;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 23px !important;
    border: 0;
  }

  .loyalty-reward-wrapper .loyalty-reward-container {
    background: #4d4d4d;
    border-radius: 8px;
    padding: 20px 35px;
    color: #fff;
  }
}

.leg-headline {
  @media screen and (max-width: 414px) {
    display: none;
  }
}

.leg-departure .segment-date::before {
  content: "Departure: ";
}

.leg-return .segment-date::before {
  content: "Return: ";
}
