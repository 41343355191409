.checkin-modal-container {
  padding: 48px 40px;
  h4 {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }

  .tab-modal-group-container {
    margin-top: 48px;
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
      margin-left: 12px;
    }
  }

  .checkin-button-container {
    display: flex;
    justify-content: center;
    margin-top: 104px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-left: 8px;
        fill: #fab500;
      }
      &:hover {
        svg {
          fill: #3c3835;
        }
      }
    }
  }
}
