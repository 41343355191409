.edit-trip-column-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  .action-button-container {
    line-height: 0px;
    padding-right: 12px;
    svg {
      transition: all 0.3s ease;
      background-color: #f5f5f5;
      border-radius: 100%;
      fill: #3c3835;
    }
    svg:hover {
      background-color: #3c3835;
      fill: #fab500;
    }
  }
  .left-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon-container {
      line-height: 0px;
      padding: 16px 0px 16px 16px;
    }
    .column-text-container {
      max-width: 310px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;
      .middle-column {
        margin-bottom: 4px;
        font-size: 10px;
        line-height: 12px;
      }
      .column-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
      .column-description {
        font-size: 10px;
        line-height: 12px;
        color: #999999;
      }
    }
  }
}
