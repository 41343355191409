@import "../../constants/variables.scss";

.Amenities {
  background: #FAB500; // Keep the yellow background
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 24px;
  justify-content: center;
  font-family: "Source Sans 3" !important;


  .content-wrapper {
    max-width: 1100px; // Slightly wider for better spacing
    width: 100%;
  }

  h2 {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 40px; // Add more space below the title
    color: #000000;
    text-align: center;
  }

  .amenities-list {
    display: flex;
    justify-content: space-between; // Spread icons across the entire width
    align-items: center;
    flex-wrap: wrap; // Ensure responsiveness
    gap: 16px; // Space between items
    padding: 0;
    margin: 0;
  }

  .amenity-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto; // No fixed width, responsive size
    text-align: center;

    .amenity-icon {
      font-size: 40px; // Larger icon
      margin-bottom: 8px; // Space between icon and text
    }

    .amenity-name {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      text-align: center;
    }
  }

  // Responsive adjustments for smaller screens
  @media screen and (max-width: 768px) {
    .amenities-list {
      justify-content: center; // Center the items instead of spreading
    }
    .amenity-item {
      .amenity-icon {
        font-size: 32px; // Slightly smaller for mobile
      }
      .amenity-name {
        font-size: 14px;
      }
    }
  }
}
