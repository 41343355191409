@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";

.motorcoachPage__content__table {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  color: $color-landline-gray;
  @include resp(768px) {
    width: 90%;
  }
  .motorcoachPage__table__label {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    @include resp(768px) {
      grid-template-columns: repeat(1, 1fr);
      text-align: left;
    }
    p {
      padding: 20px;
      font-size: 18px;
      @include resp(768px) {
        padding: 15px 0 15px 0;
      }
    }
    span {
      font-weight: 600;
    }
  }
}

.motorcoachPage__table__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include resp(768px) {
    grid-template-columns: repeat(1, 1fr);
    text-align: left;
  }
  &:nth-child(odd) {
    background-color: $color-gray-15;
  }
  &:nth-child(even) {
    background-color: $color-light-gray;
  }

  .motorcoachPage__table__cell {
    @include resp(768px) {
      &:nth-child(odd) {
        background-color: $color-light-gray;
      }
      &:nth-child(even) {
        background-color: $color-gray-15;
      }
    }
    border: 2px solid $color-white;

    .motorcoachPage__table__cell__inner {
      padding: 17px 40px;
      display: flex;
      align-items: center;

      p {
        padding-left: 20px;
        font-size: 18px;
      }
    }
    .tableIcon {
      font-size: 24px;
    }
    .tableIcon-rotate {
      transform: rotate(90deg);
    }
  }
}
