.forms-page-bus-container {
  width: 100%;
  padding: 2.5rem 2.5rem 0;
}
.forms-page-centered-title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  color: #3c3835;
}

.forms-page-image-block {
  text-align: center;
  position: relative;
  .centered-figure {
    margin: 0 auto;
    max-width: 760px;
    display: block;
    img {
      max-width: 100%;
      display: block;
      position: static;
    }
  }
}

.forms-page {
  background-color: #f5f7fa;
  max-width: 58.625rem;
  padding: 1rem 2.5rem 2.5rem;
  margin: 0 auto;
}

.forms-header {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto 48px;
}

.forms-header h1 {
  font-size: 36px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.forms-header p {
  font-size: 18px;
  color: #666;
  margin-bottom: 8px;
}

.forms-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
  h3 {
    font-size: 27px;
    font-weight: 600;
    color: #3c3835;
    margin-bottom: 0.5rem;
  }
}

.forms-section-subtitle {
  color: #000;
  font-weight: 400;
  line-height: 1.45;
  font-style: italic;
  margin: 1.5rem 0;
}

.section-divider {
  max-width: 1200px;
  margin: 0 auto;
  height: 1px;
  background-color: #e5e5e5;
}

.forms-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.group-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
}

.group-item-header-title {
  margin-top: 1rem;
  color: #000;
}

.form-card-header-container {
  background: #fab500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  max-height: 4.5rem;
  width: 11.25rem;
  max-width: 11.25rem;
  justify-content: center;
  line-height: 1.25;
  color: #444;
  .form-card-header-div {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
}

.form-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 0.625rem 1.5rem 0;
}

.form-card-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
}

.form-card-title {
  font-size: 15px;
  font-weight: 700;
}

.form-card-subtitle {
  font-size: 11px;
  font-weight: 700;
  text-align: center;
}

.form-card-list {
  justify-items: flex-start;
  line-height: 1.25;
  width: 11.25rem;
  max-width: 11.25rem;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.form-card-list li {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.form-card-list li:before {
  content: "•";
  display: inline-block;
  margin-right: 8px;
  color: #333;
}
