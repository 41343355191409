@import "../../../constants//colors.scss";

.loginPage__wrapper {
  padding: 20px;
  .loginPage--legalSection {
    font-size: 14px;
    text-align: flex-start;
    padding-top: 10px;
    color: $gray090;
    margin-bottom: 40px;
    a {
      font-weight: bold;
      color: $gray090;
    }
  }
  .loginPage--action {
    &--question {
      font-size: 14px;
      padding-bottom: 5px;
      color: $gray090;
      font-weight: bold;
    }
  }
}

.loginPage__wrapper.login--dialog {
  width: auto;
  border-radius: 5px;
}
