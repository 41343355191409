@import "../../../constants/variables.scss";

.image-text-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    font-size: 36px;
  }

  .mobile-header {
    display: none;
  }

  @media screen and (max-width: $breakpoint-small-device) {
    width: 100vw;
  //  .header {
  //    display: none;
  //  }
    .ImageWrapper {
      display: flex;
      align-items: center;
    }
  }
	
  @media screen and (max-width: 500px) {  
	.header
		{display: none;}

	.mobile-header {
      display: block; 
	  color: #fff;
      font-size: 24px;
      line-height: 30px;
      width: 300px;
      justify-self: flex-end;
      text-align: right;
      padding-right: 10px;
      margin-top: 60px;
    }
}


	
	
  .ImageWrapper {
    font-family: "Source Sans Pro", sans-serif;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    background-image: url("../../../media/home-man-traveling.png");
    background-size: cover;
    background-position: 0 14%;
    height: 430px;
    width: 100%;
	    @media screen and (max-width: 1180px) {
      background-image: url("../../../media/home-man-traveling-mobile.jpeg");
      width: 100%;
      height: 500px;
    }  
    @media screen and (max-width: 1000px) {
      background-image: url("../../../media/home-man-traveling-mobile.jpeg");
      width: 100%;
      height: 700px;
    }
    @media screen and (max-width: 850px) {
      background-image: url("../../../media/home-man-traveling-mobile.jpeg");
      height: 513px;
    }
    @media screen and (max-width: 750px) {
      background-image: url("../../../media/home-man-traveling-mobile.jpeg");
      height: 600px;
    }
    @media screen and (max-width: 450px) {
      background-image: url("../../../media/home-man-traveling-mobile.jpeg");
      height: 413px;
    }

    @media screen and (min-width: 1200px) {
      display: flex;
    }
  }

  .text-container {
    @media screen and (max-width: 900px) {
      width: 100%;
      padding: 0px 24px;
      background-color: #f5f5f5;
    }

    .TextWrapper {
      width: 450px;
      height: auto;
      padding: 30px;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 110px;

      @media screen and (min-width: 1200px) {
        width: 35%;
        margin-left: calc((100% - 1100px) / 2);
      }
      @media screen and (max-width: 900px) {		
        position: relative;
        top: 0px;
        right: 0px;
        transform: unset;
        box-shadow: 0px 2px 4px rgba(40, 34, 30, 0.12);
        width: 100%;
        max-width: 395px;
        margin: 40px auto;
		margin-top: -80px;
      }

      @media screen and (max-width: $breakpoint-small-device) {
       // margin-top: -80px;
        padding: 20px;
        padding-bottom: 0;
        margin-bottom: 0;
      }

//	 @media screen and (min-width: 768px) and (max-width: 1180px) {
 //       margin-top: -220px;
 //     }

      & h2 {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }

      & h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
      }

      & ul {
        line-height: 150%;

        li {
          margin-left: 8px;
        }
      }

      & div {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 670px) {
          flex-direction: row;
          margin-bottom: 20px;
        }
      }
    }

    @media screen and (max-width: $breakpoint-small-device) {
      ul.bus-container {
        font-size: 16px;
        line-height: 26px;
      }

      ul.bus-container li:before {
        /* the custom styled bullets */
        background-color: #ff9800;
        border-radius: 50%;
        content: "";
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 2px;
        height: 10px;
        width: 10px;
      }
    }

    ul {
      list-style: none;
    }

    .book-now-button {
      display: none;
    }

    @media screen and (max-width: $breakpoint-small-device) {
      .book-now-button {
        align-self: center;
        justify-self: center;
        display: block;
        background: #fab500;
        padding: 7px 0;
        color: #000000;
        font-size: 16px;
        line-height: 20px;
        width: 235px;
        text-align: center;
        text-decoration: none;
		margin: 30px auto 20px;
      }
    }
  }

  .filler {
    display: none;
  }

  @media screen and (max-width: $breakpoint-small-device) {
    .filler {
      display: block;
      background: #fab500;
      width: 100%;
      height: 50px;
      margin-top: -25px;
    }
  }
}
