.breadcrumbs-wrapper {
  position: sticky;
  top: 85px;
  z-index: 999;
  background-color: #28221e;

  @media screen and (max-width: 1199px) {
    top: 96px;
  }

  .breadcrumbs-container {
    display: flex;
    flex-direction: row;
    background: #4d4d4d;
    height: 66px;
    max-height: 80px;
    position: relative;
    padding: 12px 18px;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    min-width: 320px;

    .icon-wrapper {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-top: 2px;
    }

    .breadcrumbs-item {
      box-sizing: border-box;
      padding: 0;
      display: inline-flex;
      flex-direction: row;
      width: fit-content;
      text-align: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      flex-grow: 0;
      width: fit-content;
      margin-right: 5px;
    }
  }
}

.MobileBreadcrumbs-wrapper {
  position: sticky;
  top: 85px;
  z-index: 999;
  background: #ebebeb;
  display: none;

  @media screen and (max-width: 520px) {
    display: flex;
  }

  @media screen and (max-width: 1199px) {
    top: 96px;
  }

  .MobileBreadcrumbs-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .MobileBreadcrumbs-item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #999999;

    &__active {
      color: #000;
    }
  }

  .MobileBreadcrumbs-spacer {
    border-top: 1px solid #999999;
    width: 17px;
  }
}
