@import "../../styles/mixin.scss";

.AirCanadaContent {
  margin: 6.945% 3.334% 6.945% 5.556%;
  @include resp(650px) {
    margin: 13.334% 0 13.334% 6.667%;
  }

  h2 {
    margin-top: 50px;
    
    @include resp(1100px) {
      margin-top: 20px;
    }
    @include resp(650px) {
      margin-top: 20px;
    }

  }
  .AirCanadaContentTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 40px;
    color:black;

    @include resp(1100px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 30px;
    }
    @include resp(650px) {
      font-size: 30px;
      line-height: 24px;
      margin-bottom: 24px;
      margin-top: 40px;
    }
  }

  .AirCanadaContentOverview {
    margin-bottom: 60px;
  
    @include resp(1100px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 30px;
    }
    @include resp(650px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 24px;
      width: 90%;
    }

    h3 {
      @include resp(650px) {
        display: none;
      }
    }

    p {
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      width: 80%;
      margin: auto;

      @include resp(1100px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
      }
      @include resp(650px) {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 24px;
        text-align: center;
        width: 100%;
      }
    }
  }
  .AirCanadaContentContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 39px;
    @include resp(650px) {
      grid-template-columns: 100%;
      grid-template-rows: 1fr;
      gap: 20px;
    }

    .AirCanadaContentDesc {
      //border-left: 2px solid #f01428;

      h3 {
        padding: 10px;
        color:black;
        margin-bottom: 50px;
        font-size: 26px;
        border-bottom: 2px solid #f01428;
        margin-left: 20px;

        @include resp(1100px) {
          margin:0;
          margin-bottom: 20px;
          font-size: 20px;
          width: 70%;
          margin-left: 10px;
        }
        @include resp(650px) {
          margin:0;
          margin-bottom: 0px;
          width: 92%;
          text-align: center;
        }
      }

      p {
        margin-left: 30px;
        font-weight: 600;
        font-size: 20px;
        width: 70%;
        
        @include resp(1100px) {
          margin: auto;
          margin-left: 10px;
        }
        @include resp(650px) {
          margin:0;
          width: 92%;
          padding:20px;
          font-size: 14px;
        }
      }

      ul {
        margin-left: 10px;
        
        @include resp(1100px) {
          margin: auto;
          font-size: 14px;
        }
        @include resp(650px) {
          margin:0;
          width: 92%;
          margin-top:20px;
        }
      }
      li {
        list-style: disc;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 600;
        margin-right: 100px;
        margin-left: 0px;

        @include resp(1100px) {
          margin-bottom: 5px;
          font-size: 14px;
        }
        @include resp(650px) {
          margin-bottom: 5px;
          margin-right: 20px;
        }
      }
    }
  }
}
