@media screen and (max-width: 768px) {
  .form-card-header-div {
    gap: 0.5rem;
  }

  .forms-page-centered-title {
    font-size: 30px;
  }

  .forms-page {
    padding: 24px 16px;
  }

  .forms-header h1 {
    font-size: 28px;
  }

  .forms-header p {
    font-size: 16px;
  }

  .forms-section {
    padding: 24px 0;
  }

  .forms-section h3 {
    font-size: 24px;
  }

  .forms-grid {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }

  .group-item-header-title {
    font-size: 18px;
  }

  .form-card-title {
    font-size: 15px;
  }

  .form-card-subtitle {
    font-size: 12px;
  }

  .form-card-list li {
    font-size: 12px;
    line-height: 1.2; /* Tighter line spacing */
    margin-bottom: 4px; /* Smaller spacing between items */
  }
}
