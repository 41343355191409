.loyalty-program-wrapper {
  margin-bottom: 60px;
}

.loyalty-program-buttons {
  width: 50%;
  margin: 20px auto;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.loyalty-program-wrapper .loyalty-program-buttons button {
  svg {
    right: 10px;
  }
}

.miles-warning {
  padding: 5px;
  border: 1px solid red;
}