.social-container {
  display: inline-block;
  vertical-align: top;
  width: 144px;
  height: 38px;
}

.social-icon {
  display: inline-block;
  height: auto;
  padding: 10px;
  // float: left;
}
