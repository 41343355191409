.loyalty-reward-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50% !important;
}

.loyalty-reward-wrapper-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: flex-start;
}

.loyalty-reward-wrapper-item,
.loyalty-reward-wrapper-item .ticket-price-container .loyaltyPrice,
.loyalty-reward-wrapper-item .ticket-price-container .currency-style{
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
}

.loyalty-reward-new-balance .ticket-price-container .loyaltyPrice {
  font-weight: 600;
}

.loyalty-info {
  font-size: 12px;
  align-items: flex-start;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.loyalty-reward-value {
  font-size: 18px;
  line-height: 18px;

  .loyalty-style {
    color: white!important;
    font-size: 10pt!important;
    padding-top: 2px;
    padding-top: 7px;
  }
  
}

.loyalty-reward-new-balance, .loyalty-reward-balance {
  font-weight: 600;

  .loyalty-style {
    color: white!important;
    font-size: 10pt!important;
    padding-top: 2px;
  }
}

.loyalty-reward-wrapper-item-price {
  display: flex;
  align-items: center;

}
