@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";
.howItWorksIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  .iconWrapper {
    width: 114px;
    height: 114px;
    background-color: $aa-blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    @include resp(650px) {
      width: 76px;
      height: 76px;
      margin-bottom: 32px;
      .ticketsIcon {
        width: 42px;
        height: 42px;
      }
      .luggageIcon {
        width: 42px;
        height: 42px;
      }
      .seatWifiIcon {
        width: 44px;
        height: 44px;
      }
      .dollarIcon {
        width: 15px;
        height: 25px;
      }
    }
  }
  .iconDescription {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-landline-gray;
      text-align: center;
      @include resp(650px) {
        line-height: 25px;
      }
    }
    a {
      color: $aa-blue;
    }
  }
}
