.wpWrapper .wpContent {
  a {
    color: #fab500;
    text-decoration: none;
  }
  ul {
    margin-bottom: 10px;
    line-height: 1.45;
    li {
      font-size: 16px;
    }
  }
}

.wpTop {
  padding-top: 125px;
}
