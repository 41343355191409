.locations-container {
  width: 1100px;
  margin: auto;
}

.locations-container-title {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 28px;
}

.locations-container .schedule-location {
  background-color: #f0f0f0;
  padding: 30px;
  border-radius: 10px;
}

.schedule-location-panel {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.schedule-location-panel h4 {
  text-align: left;
  font-size: 24px;
  padding-bottom: 20px;
}

.schedule-location-panel button {
  height: 50px !important;
  padding: 5px;
  @media (min-width: 600px) {
    height: 40px !important;
  }
}

@media (max-width: 1199px) {
  .locations-container {
    width: 100%;
  }

  .locations-container-title {
    padding: 20px;
    text-align: center;
    font-size: 24px;
  }

  .schedule-location-panel {
    height: auto;
  }
}
