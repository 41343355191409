.change-of-plans-modal-body-container {
  .upper-container {
    margin: 0px 32px;
    .gray-circle-luggage-icon {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24px 0px 8px 0px;
    }
    .change-of-plans-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 24px;
    }
    .change-of-plans-text {
      color: #999999;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .change-of-plans-button-container {
    flex: 1;
    display: flex;
    margin: 48px 0px 24px 0px;
    justify-content: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 32px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .destructive button {
      border: 1px solid #ff4040;
      padding: 0px 24px;
      margin-left: 16px;
      transition: all 0.3s ease;
      svg {
        fill: #fff;
      }
    }
    .destructive:hover {
      svg {
        fill: #ff4040;
      }
    }
    svg {
      margin-left: 8px;
      position: static;
      top: unset;
      right: unset;
      transform: unset;
    }
  }
  @media screen and (max-width: 413px) {
    .change-of-plans-button-container {
      flex-direction: column;
      align-items: center;
      .primary button {
        margin-bottom: 24px;
      }
      .destructive button {
        margin-left: 0px;
      }
    }
  }
}
