@media (max-width: 1199px) {
  .schedule-page {
    width: 100%;
    margin: auto;
  }

  .schedule-page-title {
    border: none;
    text-align: center;
    margin: auto;
    font-weight: 600;
    font-size: 2em;
    line-height: 2em;
    margin: 10px;
  }

  .schedule-page-subtitle {
    text-align: center;
    font-size: 1.3em;
    margin: 10px;
  }

  .schedule-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .schedule-page-header-information {
    text-align: center;
    font-size: 1.2em;
    margin: 10px;
  }

  .special-notice {
    margin: auto;
    font-size: 1.2em;
    text-align: center;
    color: red;
    width: 90%;
    border: 1px dotted red;
    padding: 5px;
  }

  .schedule-search-form {
    width: 100%;
  }

  .schedule-module-title-container {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 75px;
  }

  .schedule-module-title {
    padding: 0px 20px;
    font-size: 1.5em;
    width: fit-content;
  }

  .schedule-module-title-ruler {
    background-color: orange;
    height: 4px;
    display: flex;
    flex: 1;
    margin-right: 20px;
  }

  // Pick-Up Locations
  .schedule-pickup-location {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .schedule-pickup-location-image {
    width: 100%;
    border-radius: 40px;
    padding: 20px;
  }

  .schedule-pickup-location-panel {
    padding: 20px;
  }

  .schedule-pickup-location-panel h5 {
    font-size: 1.8em;
    margin-bottom: 10px;
  }

  .schedule-pickup-location-panel a {
    font-size: 1.15em;
    color: #006fbf;
    font-weight: bold;
  }

  .schedule-pickup-location-panel p {
    line-height: 1.4em;
    font-size: 1.3em;
    margin-top: 10px;
    margin-right: 10px;
  }

  // Schedule Location

  .schedule-location {
    display: flex;
    align-items: center;
    margin: 0 auto 30px auto;
    flex-direction: column;
    padding: 20px 40px;
    max-width: 500px;
  }

  .schedule-location picture,
  .schedule-location img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .schedule-location img {
    border-radius: 10px;
    max-width: 440px;
  }

  .schedule-location-panel {
    text-align: center;
    margin-top: 10px;
  }

  .schedule-location-panel p {
    text-align: left;
    font-size: 1.2em;
    line-height: 1.4em;
  }

  .schedule-location-panel button {
    margin-top: 30px;
    background-color: #fab500;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 1.1em;
    color: black;
  }

  .schedule-book-now {
    margin-top: 20px;
    background-color: #fab500;
    width: 80%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 1.1em;
    color: black;
  }

  // Schedule Table styles
  .schedule-page-schedules-container {
    background-color: #ebebeb;
    border-radius: 10px;
    width: 100%;
    padding: 30px 30px 15px 30px;
    margin-bottom: 30px;
    text-align: center;
  }

  .schedule-page-schedules-inner-container {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 30px;
    width: 100%;
  }

  .schedules-page-schedules-disclaimer {
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 0.85em;
    line-height: 1.3em;
    text-align: center;
  }

  .schedule-table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
  }

  .schedule-table-title {
    font-size: 1.4em;
    padding-bottom: 10px;
  }

  .schedule-table-header {
    background-color: #333;
    border: 1px thin #ebebeb;
    color: white;
    width: auto;
  }

  .stop-type {
    margin-bottom: -5px;
    margin-top: 5px;
  }

  .stop-name {
    font-size: 1.4em;
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .schedule-itinerary-item {
    background-color: #fff;
  }

  .schedule-itinerary-item td {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border: 1px solid #fff;
  }

  .gray-background {
    background-color: #ebebeb;
  }

  // Our Service
  .schedule-service-container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;
    padding: 0px 20px;
  }

  .schedule-service-text {
    padding: 0px;
    margin-top: 20px;
  }

  .schedule-service-text p {
    margin-bottom: 10px;
    line-height: 1.4em;
    font-size: 1.3em;
  }

  .schedule-service-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  // Visiting Fort Collins
  .visiting-fort-collins {
    padding: 0px 20px;
  }

  .visiting-fort-collins img {
    width: 100%;
  }

  .visiting-fort-collins h4 {
    font-size: 1.6em;
    margin-bottom: 10px;
  }

  .visiting-fort-collins b {
    display: block;
    line-height: 1.4em;
    font-size: 1.3em;
    margin-top: 10px;
  }

  .visiting-fort-collins p {
    margin-right: 10%;
    line-height: 1.4em;
    font-size: 1.3em;
  }

  // FAQ

  .schedule-faq {
    width: 100%;
    margin: auto;
    padding: 40px 20px 40px 20px;
  }

  .schedule-faq-box {
    background-color: #fab500;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
  }

  .schedule-faq-box h4 {
    color: white;
    font-size: 1.5em;
  }

  .schedule-faq-box a {
    color: white;
    text-decoration: none;
  }

  .schedule-faq-box p {
    font-weight: 500;
    margin-top: 5px;
    font-size: 1.2em;
  }

  // Denver Service

  .schedule-page-location-service {
    padding: 20px;
    margin: auto;
  }

  .schedule-page-location-service img {
    width: 100%;
  }

  .schedule-page-location-service-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .schedule-page-location-service-panel div {
    width: 100%;
  }

  .schedule-page-location-service-panel h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .schedule-page-location-service-panel p {
    font-size: 1.3em;
    margin-bottom: 10px;
    line-height: 1.5em;
  }

  //  Parking

  .schedule-page-parking {
    padding: 20px;
  }

  .schedule-page-parking img {
    width: 100%;
    border-radius: 10px;
    margin-right: 3%;
  }

  .schedule-page-parking-panel {
    width: 100%;
    margin-top: 20px;
  }

  .schedule-page-parking h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .schedule-page-parking p {
    font-size: 1.3em;
    margin-bottom: 10px;
    line-height: 1.5em;
  }

  .full-width-heading.schedule-page-banner {
    margin-bottom: 0;
    padding: 1rem;
    min-height: 170px;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
    }
    span {
      color: white;
      font-family: "Source Sans 3", sans-serif !important;
      font-size: 1rem;
      z-index: 2;
      position: relative;
    }
  }

  .schedule-page-search-bg {
    background-color: white;
  }
}
