$color-green: #5aca75;
$color-dark-green: #21a742;
$color-red: #ff4040;
$color-white: #fff;

$color-landline-orange: #fab500;
$color-landline-dark-orange: #ff9800;
$color-landline-orange-hover: #faab00;

$gray: #cccccc;
$color-very-light-gray: #ebebeb;
$color-light-gray: #f5f5f5;
$color-landline-light-gray: #fafafa;
$color-gray-020: #e5e5e5;
$color-gray-10: #e0e2e2;
$color-gray-15: #ddd;
$color-gray-20: #a4a4a4;
$color-gray-30: #cdccc8;
$color-gray-40: #666666;
$color-gray-50: #999999;
$color-gray-60: #696d6f;
$color-gray-70: #474441;
$color-landline-gray: #3c3835;
$color-landline-dark-gray: #28221e;
$color-dark-bg: #333;
$color-black: #000;

$aa-blue: #0078d2;
$united-blue-dark: #044377;
$united-blue-light: #1c94f7;
