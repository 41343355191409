.MotorCoach {
  background-image: url('../../media/winterslice.jpg');  /* Background image */
  background-size: cover;  /* Ensures the image covers the full width */
  background-position: center;  /* Centers the background */
  background-repeat: no-repeat;
  width: 100%;
  padding: 40px 0;
  box-sizing: border-box;
  font-family: "Source Sans 3";
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;  /* Default: no gradient overlay on desktop */
  }

  .content-wrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;  /* Keeps the content above the background */
    display: flex;
    flex-direction: column;
    gap: 32px;

    .left-column {
      max-width: 600px;
      width: 100%;
      padding: 0;  /* No borders, no background box */
      color: #333;

      .text-content {
        .description {
          font-size: 18px;
          line-height: 1.6;
          color: #333;
          margin-bottom: 16px;
        }

        .learn-more-link {
          display: inline-flex;
          align-items: center;
          margin-top: 16px;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;
          color: #000;

          .arrow-icon {
            margin-left: 8px;
            transition: transform 0.3s ease;
          }

          &:hover .arrow-icon {
            transform: translateX(4px);
          }
        }
      }

      .timeline-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 24px;

        .step-column {
          text-align: center;

          .caption {
            font-size: 18px;
            font-weight: 600;
            color: #333;
          }
        }

        .lineSeparator {
          height: 4px;
          background-color: #fab500;
          flex-grow: 1;
          margin: 0 16px;
        }
      }
    }
  }

  /* Mobile view (768px and below) */
  @media screen and (max-width: 768px) {
    background-image: none;  /* Hide the background image in the main section */

    .content-wrapper {
      padding: 0 16px;
      max-width: 100%;  /* Allow the content to fill the full width */

      .left-column {
        max-width: none;  /* Remove width constraints */
        padding: 0;
      }
    }

    /* Add the background below the content */
    &::after {
      content: "";
      display: block;
      width: 100%;
      min-height: 250px;  /* Adjust as needed for the background height */
      background-image: url('../../media/wintercenter.jpg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.mclink {
  font-weight: 600;  /* Slightly increased weight */
  color: #000;  /* Force black text to prevent blue links on iPhone */
  text-decoration: none;  /* Removes underline */

  &:hover {
    color: #fab500;  /* Change color to #fab500 on hover */
  }
}
