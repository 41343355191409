.trip-container {
  .flights-manage-booking-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 50%;
    margin: 20px auto 0px auto;
    .text {
      padding: 20px;
      text-align: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      width: 100%;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e5e5e5;
    }
  }
  .return-segment-container,
  .departure-segment-container {
    padding: 20px 0;
  }
  .collapsiblebutton-toogle-wrapper {
    border-bottom: none;
  }
  @media screen and (max-width: 700px) {
    .flights-manage-booking-container {
      max-width: 100%;
    }
    .change-seat {
      flex-direction: column;
    }
  }
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  margin-bottom: 20px;
  &:nth-child(1) {
    border-radius: 0px 6px 0 0;
  }
  .leg-wrapper {
    padding-bottom: 64px;
    .legconnection-container {
      padding-bottom: 0px;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    .leg-title {
      display: flex;
      span:nth-child(1) {
        position: absolute;
      }
      span:nth-child(2) {
        display: block;
        margin: 0 auto;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .trip-additional-info {
    display: block;
    margin-top: 5px;
    font-style: italic;
    font-size: 12px;
    line-height: 12px;
    color: #696d6f;
  }

  .trip-leg-container {
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      display: flex;
    }
  }

  // .trip-check-in-button button {
  //   padding: 0;
  //   text-align: left;
  //   padding-left: 10px;
  //   width: 100%;
  //   @media screen and (max-width: 700px) {
  //     margin-top: 20px;
  //     margin-right: 30px;
  //   }
  // }
  // .trip-check-in-button-icon-boarding {
  //   margin-top: 4px;
  //   @media screen and (max-width: 414px) {
  //     display: none;
  //   }
  // }

  @media screen and (max-width: 414px) {
    // .trip-check-in-button button {
    //   min-width: 100px;
    //   text-align: center;
    //   padding-left: 0;
    // }
    .trip-check-in-button-icon {
      display: none;
    }
  }
  .checkin-button-wrapper {
    margin-right: 16px;
  }
  .checkin-button-wrapper,
  .boarding-button-wrapper {
    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    svg {
      margin-left: 8px;
    }
  }

  .trip-depart-container {
    .pnr-cancel-container {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding-right: 15px;
      }
      &.canceled {
        color: #ff4040;
      }
    }
    .trip-depart-title {
      display: flex;
      justify-content: flex-end;
      font-size: 18px;
      padding-top: 10px;
      border-bottom: 1px solid #3c3835;
      padding: 20px 20px 0 20px;
      padding-bottom: 10px;
      .title {
        font-weight: 600;
        font-size: 18px;
      }
    }

    .trip-depart-title-past-trip {
      color: #ff4040;
    }

    .trip-depart-title-current-trip {
      color: #00c08b;
    }

    .trip-depart-title span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: space-around;
      font-size: 20px;
      line-height: 40px;
      color: #3c3835;
    }

    .additional-info-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  .change-seat {
    display: flex;
    .change-seat-container {
      .passengers-container {
        .titles {
          display: flex;
          padding-bottom: 15px;
          font-weight: 600;
          font-size: 16px;
          line-height: 15px;
          .name-title {
            flex: 1;
          }
          .seat-title {
            flex: 1;
          }
        }
      }
      border-top: 1px solid rgba(60, 56, 53, 0.22);
      border-bottom: 1px solid rgba(60, 56, 53, 0.22);
      margin-top: 36px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex: 1;
      .passenger {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
      }
      .name {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .ticket-number {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .seats-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        .seat-wrapper {
          display: flex;
          .seatplace {
            width: 35px;
            height: 35px;
            font-size: 16px;
            .border {
              width: 25px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
    .leg-connection-vertical-wrapper {
      flex: 1;
    }
  }
  @media screen and (max-width: 700px) {
    .return-segment {
      flex-direction: column;
    }
  }
  .return-segment {
    display: flex;
    padding: 0 20px;
  }
  .collapsiblebutton-wrapper {
    padding: 0 20px;
  }
}

.edit-trip-modal {
  @media screen and (max-width: 340px) {
    .modalButton button {
      padding: 5px 0px;
    }
  }
  .modalButton {
    margin-bottom: 10px !important;
  }
  .modalButton:last-child {
    margin-bottom: 0px !important;
  }
}

.edit-trip-button {
  display: flex;
  padding: 0 20px;
  .edit-trip-container {
    @media screen and (max-width: 480px) {
      padding: 0px 20px;
    }
    @media screen and (max-width: 380px) {
      .text-container {
        padding: 0px 20px;
      }
    }
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .edit-trip-button-wrapper {
      margin: 24px 0px;
      background-color: #f5f5f5;
      display: flex;
      flex-basis: 484px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .text-container {
        text-align: center;
        max-width: 312px;
        font-size: 14px;
        line-height: 20px;
        margin: 24px 0px;
      }
      button {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
      }
      button:hover {
        svg {
          fill: #3c3835;
        }
      }
      svg {
        fill: #fab500;
        margin-left: 8px;
      }
    }
  }
}
.trip-leg-container-black-car {
  background-color: #dcdcdb;
  .collapsiblebutton-wrapper {
    background-color: #dcdcdb;
  }
}
@media only screen and (max-width: 365px) {
  .trip-container {
    .checkin-button-wrapper {
      margin: 0 0 4px 0;
    }
    .checkin-button-wrapper,
    .boarding-button-wrapper {
      width: 160px;
      button {
        font-size: 14px;
      }
    }
    .leg-wrapper {
      .leg-title {
        span:nth-child(2) {
          font-size: 14px;
          margin: 0 0 0 70px;
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .trip-container {
    .checkin-button-wrapper {
      margin: 0 0 4px 0;
    }
    .checkin-button-wrapper,
    .boarding-button-wrapper {
      width: 160px;
      button {
        font-size: 14px;
      }
    }
    .leg-wrapper {
      .leg-title {
        span:nth-child(2) {
          font-size: 14px;
          margin: 0 0 0 70px;
        }
      }
    }
  }
}
@media only screen and (max-width: 540px) {
  .trip-container {
    .checkin-button-wrapper {
      margin: 0 0 4px 0;
    }
    .checkin-button-wrapper,
    .boarding-button-wrapper {
      width: 200px;
      button {
        font-size: 14px;
      }
    }
    .leg-wrapper {
      .leg-title {
        span:nth-child(2) {
          font-size: 14px;
          margin: 0 0 0 70px;
        }
      }
    }
  }
}
