.reservation-return-page-container {
  background-color: #f5f5f5;
  height: 100%;

  .edit-search-container {
    flex-direction: column;
    .search-header {
      flex-grow: 1;
      width: 100%;
    }
    .search-container {
      width: 100%;
      flex: 1;

      .Search__container {
        width: 100%;
      }

      .searchform-wrapper {
        background: #fff;
        padding: 20px 0;
        max-width: 800px;
        width: 100%;
        flex-grow: 1;
        margin: 0 auto;
      }

      .Search--Form {
        position: static;
      }
    }
    .mobile-search-button button {
      bottom: -36px;
    }
  }

  // Hide suncountry banner when editing search
  .suncountry-banner-container.sticky-edit-search-position {
    display: none;
  }

  .ReactVirtualized__List {
    overflow: unset !important;
    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: unset !important;
    }
  }

  .reservation-section-wrapper {
    background-color: #f5f5f5;
    position: relative;
    top: -50px;
    height: auto;
  }
  .Section-container {
    position: sticky;
    top: 85px;
    z-index: 999;
  }
  .reservation-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    position: sticky;
    top: 126px;
    z-index: 1000;

    @media screen and (max-width: 1199px) {
      top: 136px;
    }
    @media screen and (max-width: 520px) {
      top: 96px;
      margin-bottom: 50px;
    }
  }
  .search-filters-wrapper {
    position: sticky;
    top: 180px;
    z-index: 1;
  }
  .suncountry-banner-container {
    top: 180px;
  }

  .reservation-return-wrapper {
    .reservation-body {
      display: flex;
      flex-direction: column;

      .departure-date {
        margin-top: 40px;
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-size: 20px;
        font-weight: 400;
      }
    }
    display: flex;
    .itinerary-list-container {
      background-color: #f5f5f5;
      * {
        outline: none !important;
      }
      flex: 3;
      padding-top: 10px;

      @media screen and (max-width: 1199px) {
        padding: 0 10px;
        padding-top: 10px;
      }
    }
    .filters-container {
      flex: 1;
      margin-left: 20px;
    }
    .reservation-container {
      flex: 1;
    }
    .filters-mobile-container {
      display: none;
      align-items: center;
      margin: 0px 20px;
      padding: 10px 0px;
      border-bottom: 1px solid #cdccc8;
      cursor: pointer;
      .text {
        padding-left: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .filters-container {
      display: none;
    }
    .filters-mobile-container {
      display: flex !important;
      justify-content: flex-end;

      .filters-mobile-wrapper {
        cursor: pointer;
      }
    }
  }
  .segment-date {
    display: none;
  }
}

@media screen and (max-width: 330px) {
  .itinerary-list-container {
    padding-top: 40px;
  }
}

@media screen and (max-width: 414px) {
  .reservation-return-page-breadcrumbs {
    display: none;
  }
}
