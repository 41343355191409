.week-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    // Removed background color and shadow to blend with parent
    // background: white;
    // border-radius: 8px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    
    .nav-button {
        padding: 8px;
        border: none;
        background: none;
        border-radius: 50%;
        cursor: pointer;
        color: #333;
        transition: background-color 0.2s;

        &:hover:not(:disabled) {
            background-color: rgba(0, 0, 0, 0.05); // Lighter hover effect for grey background
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    .week-display {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 200px;
        margin: 0 16px;

        .week-date {
            font-size: 18px;
            font-weight: 600;
            color: #333;
        }

        .week-count {
            font-size: 14px;
            color: #666;
            margin-top: 4px;
        }
    }
}