.page-layout-line {
  height: 85px;

  @media screen and (max-width: 1199px) {
    height: 96px;
  }
}


.DayPicker_transitionContainer__vertical {
  top: 100px;
}

.DateRangePicker_closeButton {
  top: 100px!important;
}