@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";
.header {
  .content {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    width: 88.88%;
    margin: 0 auto;

    @include resp(650px) {
      width: 86.667%;
      padding: 24px 0;
    }
    .logoWrapper {
      display: flex;
      align-items: center;
      img {
        @include resp(650px) {
          width: 120px;
        }
      }
    }
    .rightContent {
      display: flex;
      align-items: center;
      .callUs {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 0;
        &:hover {
          cursor: pointer;
        }
        @include resp(650px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .phoneNumber {
        display: flex;
        align-items: center;
        a {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: $color-gray-50;
          margin-left: 24px;

          @include resp(650px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
