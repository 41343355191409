.page-onboard {
  padding-bottom: 0.625rem;
}

.page-onboard-media-block {
  display: grid;
  grid-template-columns: 50% 1fr;
  margin-bottom: 0.625rem;
  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
}

.page-onboard-media-img {
  height: 100%;
  min-height: 250px;
  background-size: cover;
}

.page-onboard-media-text-content {
  padding: 1.875rem 1.25rem;

  p {
    line-height: 1.5;
    margin: 0 0 1.125rem 0;
    font-size: 1.125rem;
  }

  ul {
    margin: 0 0 1.875rem 1rem;
    @media (min-width: 600px) {
      margin: 0 0 2.5rem 1rem;
    }
    li {
      line-height: 1.7;
      margin: 0.313rem 0 0 1.25rem;
    }
  }

  a {
    color: #fab500;
    text-decoration: underline;
  }

  @media (min-width: 700px) {
    padding: 2.5rem;
  }
}

.page-onboard-landline-logo {
  width: 200px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
}

.page-onboard-title {
  font-size: 2rem;
  margin: 2.188rem auto 1.25rem;
  text-align: left;
  line-height: 1;
  @media (min-width: 600px) {
    font-size: 3rem;
    margin: 3.75rem auto 1.875rem;
  }
}

.page-onboard-subtitle {
  font-size: 1.5rem;
  margin: 2.188rem 0 1.25rem;
  @media (min-width: 600px) {
    font-size: 2rem;
    margin: 2.813rem auto 1.563rem;
  }
}

.page-onboard-empty-h4 {
  padding: 1.25rem;
  margin: 2.813rem auto 0rem;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  overflow-wrap: break-word;
  @media (min-width: 600px) {
    font-size: 2rem;
  }
}

.page-onboard-social-media {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  .social-container {
    width: auto;
    height: auto;
    .social-icon {
      a img {
        width: 36px !important;
        background-color: #1778f2;
        color: #fff;
        border-radius: 50%;
      }
    }
  }
}

.page-onboard-social-media .social-container .social-icon:nth-child(2) a img {
  background-color: #1da1f2;
}

.page-onboard-social-media .social-container .social-icon:nth-child(3) a img {
  background-color: #f00075;
}

.page-onboard-bottom-p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
  margin: 0 0 1em 0;
  a {
    color: #fab500;
    text-decoration: underline;
  }
}
