.leg-payment-wrapper {
  margin: 0px -20px;
  padding: 0px 20px;
  .connection-time-container {
    justify-content: flex-end !important;
  }
  .title-wrapper {
    justify-content: flex-end !important;
    .title {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-top: -25px;
    }
  }
}
.leg-headline {
  padding-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.leg-wrapper-black-car {
  background-color: #e5e5e5;
}
.leg-headline-black-car {
  padding-top: 20px;
}

.leg-wrapper-return-black-car {
  background-color: #e5e5e5;
  margin: 0px -20px;
  padding: 0px 20px;
  .title-wrapper {
    justify-content: flex-end;
    .title {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-top: -25px;
    }
  }
}

.trip-summary-container {
  .leg-return {
    margin-top: 20px;
  }
}
