.edit-trip-modal-layout-container {
  padding-bottom: 10px;
  .go-back-button-container {
    display: flex;
    align-items: center;
    position: absolute;
    left: 18px;
    top: 16px;
    span {
      margin-left: 8px;
    }
    cursor: pointer;
  }
  .gray-circle-editing-icon {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0px 24px 0px;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 48px;
  }
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    margin-bottom: 40px;
  }
  .buttons-container {
    margin: 0px 40px 0px 40px;
    display: flex;
  }
  .horizontal-break-line {
    flex: 1;
    margin: 24px;
  }
}
