.faq-page {
  margin: auto;
  font-family: Source Sans Pro, sans-serif;
}

.faq-question {
  background-color: #f2f2f2;

}

@media (min-width: 1200px) {
  .faq-page {
    width: 1000px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .faq-header h2 {
    font-size: 35px;
    line-height: 1.2;
  }

  .faq-header h5 {
    color: #ff9800;
    font-size: 22px;
  }

  .faq-section {
    margin-top: 50px;
  }

  .faq-section h3 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .faq-back {
    color: #fab500;
    text-decoration: none;
    font-size: 20px;
  }

  .faq-question {
    padding: 20px;
    margin-top: 30px;
  }

  .faq-question h4 {
    font-size: 27px;
  }

  .faq-question > div {
    margin-top: 20px;
  }

  .faq-question > p {
    margin-top: 20px;
  }

  .faq-question > div > p {
    margin-top: 20px;
  }

  .faq-question p {
    line-height: 1.45;
    font-weight: 400;
    font-size: 20px;
    color: #505050;
  }

  .faq-question a {
    color: #fab500;
  }

  .faq-question > div > ul {
    margin-left: 40px;
  }

  .faq-question > div > ul > li {
    font-size: 18px;
    line-height: 18px;
    height: 25px;
    color: #505050;
  }
}

@media (max-width: 1200px) {
  .faq-page {
    padding: 20px;
  }

  .faq-header h2 {
    font-size: 35px;
    line-height: 1.2;
  }

  .faq-header h5 {
    color: #ff9800;
    font-size: 22px;
  }

  .faq-section {
    margin-top: 50px;
  }

  .faq-section h3 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .faq-back {
    color: #fab500;
    text-decoration: none;
    font-size: 20px;
  }

  .faq-question {
    padding: 20px;
    margin-top: 30px;
    height: fit-content;
  }

  .faq-question h4 {
    font-size: 27px;
  }

  .faq-question > div {
    margin-top: 20px;
  }

  .faq-question > p {
    margin-top: 20px;
  }

  .faq-question > div > p {
    margin-top: 20px;
  }

  .faq-question p {
    line-height: 1.45;
    font-weight: 400;
    font-size: 20px;
    color: #505050;
  }

  .faq-question a {
    color: #fab500;
  }

  .faq-question > div > ul {
    margin-left: 40px;
  }

  .faq-question > div > ul > li {
    font-size: 18px;
    line-height: 18px;
    height: 25px;
    color: #505050;
  }
}