.referal-page-wrapper {
  padding: 10px 20px 0 20px;
  background-color: #fff;
  height: 100%;
  .collapsiblebutton-toogle-wrapper {
    padding: 0;
    align-items: center;
    height: 50px;
    padding-bottom: 10px;
  }
  .referal-page-button {
    width: 50%;
    margin: 20px auto;
    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }
  .referal-page-succesfull-referals {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .show-desktop {
    display: block;
  }

  .show-mobile {
    display: none;
  }

  .referral-expl-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  .referral-expl-element h4 {
    padding-bottom: 20px;
    font-size: 22px;
    width: 65%;
    margin: auto;
    text-align: center;
  }

  .referral-expl-element ol {
    margin-left: 20px;
    width: 65%;
    margin: auto;
  }

  .referral-expl-element li {
    margin-top: 0px;
    line-height: 1.25rem;
  }
}

@media (max-width: 1200px) {
  .show-desktop {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .referral-expl-element {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .referral-expl-element h4 {
    padding-bottom: 20px;
    font-size: 22px;
    width: 65%;
    margin: auto;
    text-align: center;
  }

  .referral-expl-element ol {
    margin-left: 20px;
    width: 65%;
    margin: auto;
  }

  .referral-expl-element li {
    margin-top: 0px;
    line-height: 1.25rem;
  }
}

.referral-banner {
  width: 100%;
  cursor: pointer;
}
