.tabs-container {
  display: flex;
  background-color: #000;
  height: 64px;
  padding: 0 calc((100% - 1100px) / 2);
  .tab-wrapper {
    position: relative;
    &.first {
      border-top-left-radius: 6px;
    }
    &.last {
      border-top-right-radius: 6px;
    }

    @media screen and (max-width: 1199px) {
      &.first {
        border-top-left-radius: 0px;
      }

      &.last {
        border-top-right-radius: 0px;
      }
    }

    display: flex;
    flex: 1;
    background-color: #000 ;
    justify-content: center;
    align-items: center;
    a {
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
    }
    .link {
      &.active {
        color: #fab500;
        .hoverBox {
          display: block;
        }
      }
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      font-family: "Source Sans 3", sans-serif;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2px;
      line-height: 23px;
      .hoverBox {
        display: none;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        height: 5px;
        width: 70%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: #fab500;
      }
    }
  }
  .tab-wrapper:hover {
    a {
      color: #fab500;
    }
    .hoverBox {
      display: block;
    }
  }
  .separator-wrapper {
    display: flex;
    align-self: center;
    height: 30%;
    width: 2px;
    background-color: #000;
    .separator-line {
      align-self: center;
    }
  }
}
