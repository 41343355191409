.AirCanadaNavigation {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid black;
    border-left: 1px solid black;

    @media only screen and (orientation: landscape) {
        grid-template-columns: 25% 25% 25% 25%;
        height: 8dvh;
    }

    @media only screen and (orientation: portrait) {
        grid-template-columns: 50% 50%;
        height: 10dvh;
    }
}

.AirCanadaNavigationItem {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: #f01428;
    color: white;

    div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div:hover {
        background-color: #fd5765;
    }

    @media only screen and (orientation: landscape) {
        width: 25dvw;
        flex:25%;
        font-size: 24px;
    }

    @media only screen and (orientation: portrait) {
        width: 50dvw;
        flex:50%;
    }

    a {
        color: white;
    }

    a:hover {
        color: #e2e2e2;
        transition: all 0.5s;
    }
}