.icon1-grid.csu-icon-section {
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.otherlocations-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.otherlocations-container .otherlocations {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f8f8f8;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.otherlocations-container .otherlocations:hover {
  background-color: #f0f0f0;
}
