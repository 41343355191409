.loyalty-buy-points-page-content {
  background-color: #fff;
  padding: 20px;

  .currency-style,
  .defaultPrice {
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 16px !important;
  }
}
@media screen and (max-width: 1200px) {
  .loyalty-buy-points-page-content {
    padding: 20px 20px 0px 20px;
  }
}

.loyalty-buy-points-page-LoyaltyPointsBalance-container {
  padding-bottom: 40px;
}
