@import "../../styles/mixin.scss";

.faq {
  .content {
    width: 80.556%;
    margin: 0 auto;
    @include respUp(2000px) {
      width: 50%;
    }
    @include resp(650px) {
      width: 86.667%;
    }
    .title {
      font-size: 40px;
      line-height: 48px;
      font-weight: 700;
      text-align: center;
      margin-top: 9.445%;
      margin-bottom: 9.445%;
      @include resp(1100px) {
        font-size: 30px;
        line-height: 36px;
      }
      @include resp(650px) {
        font-size: 30px;
        line-height: 24px;
        margin-top: 64px;
        margin-bottom: 40px;
      }
    }
  }
}
