.canceled-container {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .big-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 56px;
    max-width: 250px;
  }
  .small-text {
    max-width: 300px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 56px;
    .orange-text {
      color: #fab500;
    }
  }
}

.cancel-confirmation-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .big-title {
    max-width: 300px;
    margin-top: 48px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    padding: 0px 32px;
    .red-text {
      color: #ff4040;
    }
  }

  .cancel-confirmation-buttons-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .bottom-content-container {
    margin-top: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .important-info {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
    }
    .refund-container {
      font-size: 12px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 32px;
      span {
        color: #fab500;
      }
    }
  }
}
