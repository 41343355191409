.alert-container {
  padding: 10px;
  font-size: 16px;
  border-radius: 6px;
  text-align: center;
  font-weight: 400;
  &.red-alert {
    border: 1px solid #ff4040;
    color: #ff4040;
  }
  &.orange-alert {
    border: 1px solid #ff9800;
    color: #ff9800;
  }
  &.green-alert {
    border: 1px solid #00c08b;
    color: #00c08b;
  }
}
