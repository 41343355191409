.SignUp--top {
  background-color: #fff;
}
.singup-inputfield-error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  color: #ff4040;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.singup-inputfield-pwrequirements {
  @extend .singup-inputfield-error;
  color: #696d6f;
}

.subscription-checkbox-wrapper {
  margin: 6px 0px 16px 0px;
}
