.pickup-details-page {
  background-color: #fff;
}
@media screen and (max-width: 1200px) {
  .pickup-details-page-container {
    padding: 0px 20px;
  }
}
.pickup-details-page-container {
  padding: 0px 20px;
  .dropdown-container {
    display: flex;
  }
  .street-container {
    display: flex;
    .street-number-wrapper {
      margin-right: 15px;
      flex: 1;
    }
    .street-address-wrapper {
      flex: 6;
    }
  }
  .zip-state-container {
    .zip-code-wrapper {
      margin-right: 15px;
      flex: 1;
    }
    .state-wrapper {
      flex: 6;
    }
  }
  .pickup-information-container {
    display: flex;
    .first-wrapper {
      margin-right: 14px;
      flex: 3;
    }
    .second-wrapper {
      flex: 2;
      .rdt {
        input {
          min-width: 140px;
          min-height: 40px;
          border: 1px solid #cdccc8;
          color: #3c3835;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 0 15px;
          font-family: "Source Sans Pro", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          position: relative;
        }
      }
    }
  }
  .button-wrapper {
    margin-bottom: 20px;
    width: 100%;
  }
  .datetime-container {
    border: none;
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    margin: 0 0 20px 0;
    .datetime-label {
      position: absolute;
      top: -8px;
      text-transform: uppercase;
      left: 25px;
      padding: 0 10px;
      background-color: white;
      color: #3c3835;
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      z-index: 1;
    }
    .rdt {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .pickup-details-page-container {
    flex-direction: column;
    .pickup-information-container {
      display: block;
      .first-wrapper {
        margin-right: 0px;
      }
    }
  }
}
.pick-up-details-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
