.loyalty-buy-points-confirmation-page-wrapper {
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
}
.loyalty-buy-points-confirmation-page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  padding: 20px 0;
}
.loyalty-buy-points-confirmation-page-content {
  padding: 0 20px;
  height: 100%;
}
.loyalty-buy-points-confirmation-page-LoyaltyPointsBalance-container {
  padding-bottom: 80px;
}
