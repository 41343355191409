@mixin resp($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

.carouselRatingsContainer {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include resp(550px) {
    padding: 0px;
  }
  .starsContainer {
    .dividerTop {
      height: 1px;
      width: 32px;
      margin-top: 48px;
      margin-bottom: 24px;
      background-color: #28221e;
    }
  }
  .dividerBottom {
    height: 1px;
    width: 32px;
    margin-top: 24px;
    background-color: #28221e;
  }
  p {
    font-size: 18px;
    line-height: 28px;
    color: #28221e;
    width: 560px;
    font-weight: 400;
    @include resp(650px) {
      width: 86.67%;
      font-size: 16px;
      line-height: 25px;
    }
  }
  .name {
    margin: 30px 0px 8px 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
  }
  .role {
    font-size: 14px;
    line-height: 20px;
    color: #4d4d4d;
    text-transform: uppercase;
    @include resp(650px) {
      width: 243px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
