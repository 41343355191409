.top-menu-profile-wrapper {
  color: #fff;
  position: relative;
  font-size: 20px;
  line-height: 20px;
  @media screen and (max-width: 1200px) {
    position: initial;
  }
}

.top-menu-profile-content {
  z-index: 100;
  padding: 20px;
  background: #3c3835;
  position: absolute;
  right: 0;
  width: 414px;
  top: 62px;

  .ticket-price-container .defaultPrice {
    font-size: 16px;
    color: #fff;
  }

  @media screen and (max-width: 414px) {
    width: 100vw;
    right: 0;
  }
}

.top-menu-profile-name {
  color: #fab500;
}

.top-menu-profile-navlinks {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 23px;
  height: 50px;
  color: #fab500;
  text-align: left;
  border-top: 1px solid #fab500;
}

.top-menu-profile-navlinks:hover {
  font-weight: bold;
}

.top-menu-profile-navlinks-wrapper .top-menu-profile-navlinks:last-child {
  border-bottom: 1px solid #fab500;
}

.top-menu-profile-name-wrapper {
  height: 40px;
  display: flex;
}

.top-menu-profile-logout button {
  text-align: left;

  svg {
    fill: #fff;
    top: 22px;
    right: 10px;
  }
  &:hover {
    svg {
      fill: #ff4040;
    }
  }
}

.top-menu-icons {
  cursor: pointer;
}

.top-menu-profile-icon-wrapper {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    margin-left: 10px;
  }
}
