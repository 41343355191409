.BookEarlyAndSave {
  background: #fab500;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 24px;
  justify-content: center;
  font-family: "Source Sans 3";

  .content-wrapper {
    max-width: 1100px;
    width: 100%;
  }

  h2 {
    font-family: "Source Sans 3", sans-serif;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 32px;
    color: #000000;
    text-align: center;
  }

  .price-list {
    display: grid;
    gap: 12px; // Consistent gap between cards
    width: 100%;
    grid-template-columns: 1fr; // Default single-column layout
  }

  .price-row {
    text-decoration: none; // Remove underline from the link
    color: inherit; 

    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: flex-start;
    height: auto;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #f7f7f7;
    }

    .location-connector {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px; // Space between locations
    
      .location {
        display: flex;
        align-items: top;
        width: 100%;
    
        .bullet {
          margin-top: 4px;
          position: absolute;
          flex-shrink: 0; // Prevent the bullet from shrinking
          width: 12px;
          height: 12px;
          background-color: #000;
          border-radius: 100%; // Makes it a circle
          margin-right: 8px;
          margin-left: 4px;
        }
    
        .location-name {
          position: absolute;
          left: 50px;
          font-size: 16px;
          font-weight: 600;
          color: #333;
          line-height: 1.2; // Line height to space multiline text evenly
          max-width: calc(100% - 24px); // Prevents it from pushing the layout
          overflow-wrap: break-word;
        }
    
        .endbullet {
          background-color: transparent; // No background for the icon
          width: auto; // Let the icon size itself
          height: auto;
          font-size: 24px; // Font size for the airplane icon
          color: #000;
          margin-right: 4px;
          margin-top: -4px;
          flex-shrink: 0;
        }
      }
    
      .connector-line {
        position: relative;
        top: 8px;
        width: 4px;
        background-color: #FAB500;
        height: 48px; // Fixed height to avoid distortion
        margin-left: 8px; // Pushes the line to the correct alignment
        align-self: left; // Keeps the line centered relative to the bullet
      }
    }
    
    .price-section {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-top: 16px;

      .from-text {
        font-size: 14px;
        font-weight: 400;
        color: #777;
        margin-right: 8px;
      }

      .price {
        font-size: 28px;
        font-weight: 700;
        color: #000;
      }
    }

    .pill {
      background-color: #FAB500;
      color: #000;
      border-radius: 100px;
      padding: 0px 14px;
      font-size: 14px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 16px;
      font-family: "Source Sans 3";
    }
  }

  // Two-column layout for tablet
  @media screen and (min-width: 768px) {
    .price-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // Four-column layout for larger screens
  @media screen and (min-width: 1200px) {
    .price-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.BookEarlyAndSave {
  .price-row {
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
 

      .icon-arrow {

        position: absolute;
        bottom: 24px;
        right: 24px;
        text-decoration: none;

        font-size: 24px; // Size of the arrow
        font-weight: bold;
        color: #fab500;
        transition: transform 0.2s ease-in-out;
      }

      &:hover .icon-arrow {
        transform: translateX(4px); // Adds a sliding effect when hovered
      }
    
  }
}
