.reservation-summary-page-wrapper {
  background-color: #fff;
  height: 100%;
  .edit-search-container {
    flex-direction: column;
    .search-header {
      flex-grow: 1;
      width: 100%;
    }
    .search-container {
      width: 100%;
      flex: 1;

      .Search__container {
        width: 100%;
      }

      .searchform-wrapper {
        background: #fff;
        padding: 20px 0;
        max-width: 800px;
        width: 100%;
        flex-grow: 1;
        margin: 0 auto;
      }

      .Search--Form {
        position: static;
      }
    }

    .mobile-search-button button {
      bottom: -36px;
    }
  }

  // Hide suncountry banner when editing search
  .suncountry-banner-container.sticky-edit-search-position {
    display: none;
  }

  .reservation-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    position: sticky;
    top: 126px;
    z-index: 1000;

    @media screen and (max-width: 1199px) {
      top: 136px;
    }
    @media screen and (max-width: 520px) {
      top: 96px;
      margin-bottom: 50px;
    }
  }

  .SectionWrapper {
    background-color: transparent;
  }
  .pagetitle {
    margin-top: 0;
    border-bottom: 0;
    @media screen and (max-width: 414px) {
      display: none;
    }
  }
  .reservation-section-wrapper {
    position: relative;
    top: -50px;
    height: auto;
  }
  .suncountry-banner-container {
    display: none !important;
  }

  .summary-page-total-price {
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 0px 0px 16px 16px;
    margin-bottom: 40px;

    .summary-price-total-text {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
    }

    .summary-price-total-passenger-count {
      color: #999;
    }

    .defaultPrice {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
      text-align: right;
    }

    .summary-page-total-price-left {
    }
    .summary-page-total-price-right {
      min-width: 200px;
      margin-top: 20px;
      .summary-page__continue button {
        background: #fab500;
        color: #000;
      }
      .ticket-price-container {
        margin-bottom: 25px;
        justify-content: flex-end;
      }
      .currency-style {
        font-weight: 400;
        font-size: 30px;
        line-height: 16px;
      }
      .defaultPrice {
        font-weight: 400;
        font-size: 30px;
        line-height: 16px;
        margin-bottom: 0;
      }

      .loyalty-style {
        display: flex;
        font-size: 16px;
        line-height: 8px;
        align-items: flex-end;
      }
    }
  }
  // Hide the date within the leg connection since we're displaying it in the PageTitle
  .legconnection-container .date-wrapper {
    display: none;
  }
}

@media screen and (max-width: 414px) {
  .reservation-summary-page-breadcrumbs {
    display: none;
  }
}

.departure-ticket .segment-date::before {
  content: "Departure: ";
}

.return-ticket .segment-date::before {
  content: "Return: ";
}

.summary-page-total-price-mobile {
  display: none;
}

@media screen and (max-width: 414px) {
  .summary-page-total-price {
    display: none !important;
  }
  .summary-page-total-price-mobile {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    flex-direction: column;

    .summary-page__continue {
      max-width: 220px;
      align-self: center;
    }

    button {
      background: #fab500;
      color: #000;
    }
  }
  .summary-page-total-price-mobile-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    align-self: flex-end;

    .ticket-price-container {
      .currency-style,
      .defaultPrice {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 24px;
      }
    }
  }
}
