@import "../../styles/mixin.scss";

.B2BHowItWorks {
  margin: 6.945% 3.334% 6.945% 5.556%;
  @include resp(650px) {
    margin: 13.334% 0 13.334% 6.667%;
  }
  .B2BHowItWorksTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 40px;

    @include resp(1100px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 30px;
    }
    @include resp(650px) {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
  .B2BhowItWorksContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 39px;
    @include resp(650px) {
      grid-template-columns: 100%;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      gap: 20px;
    }
  }
}
