.top-menu-wrapper {
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: #000;
  flex-direction: row;
  align-content: center;
  align-items: center;
  z-index: 1001;
  width: 100%;
  height: 85px;

  .book-now-button {
    font-family: "Source Sans 3", sans-serif !important;
    font-weight: 600;
    font-size: 16px;
    color: #3c3835;
    border-radius: 100px;
    background-color: #fab500;
    margin-right: 3px;
    cursor: pointer;
    text-decoration: none;
    width: auto;
    padding: 6px 10px;
    height: 30px;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .book-now-button:hover {
    background-color: #ff9800;
  }
  @media screen and (max-width: 1199px) {
    height: 96px;

    .book-now-button {
      display: none;
    }
  }

  .logo-links-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 1063px;
    height: 52px;
    @media screen and (max-width: 1199px) {
      width: 100%;
      margin: 0 27px;
    }
  }
}

.top-menu-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.logo-points-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 0;
  @media screen and (min-width: 1200px) {
    flex-direction: column;
  }

  
  .loyalty-points {
    padding: 0;
    padding-left: 10px;
    align-items: center;
  }
}

.loyalty-points {
  display: flex;
  justify-content: center;

  .currency-style {
    display: none;
  }
  .defaultPrice {
    color: #cdccc8;
    font-size: 14px;
  }
  @media screen and (max-width: 1999px) {
    color: #ffffff;
  }
}

