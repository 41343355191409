.about-us-container {
    margin: auto;
}

.about-us-headline h3 {
    color: #333;
    font-weight: 700;
}

.about-us-headline h4 {
    font-weight: 600;
    color: #333;
}

.about-us-benefits {
    display: flex;
    margin-bottom: 40px;
}

.benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.benefit-item h4 {
    border-bottom: 1px solid #CCC;
    width: 80%;
    text-align: center;
    margin-bottom: 10px;
}

.about-us-carriers {
    margin: auto;
}

.carrier-item {
    margin: auto;
}

@media (min-width: 1200px) {
    .about-us-container {
        width: 1000px;
        padding: 30px 0px;
    }

    .about-us-headline h3 {
        font-size: 48px;
    }

    .about-us-headline h4 {
        font-size: 26px;
        line-height: 38px;
        margin-top: 30px;
    }

    .benefit-item {
        width: 25%;
        min-width: 25%;
    }

    .benefit-item h4 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
    }

    .benefit-item p {
        text-align: center;
        font-size: 16px;
        padding: 14px 20px;
        color: #3c3835;
        line-height: 22px;
    }

    .carrier-item h4 {
        color: #3C3835;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .carrier-item ol {
        margin-left: 50px;
    }

    .carrier-item li {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }

    .carrier-action {
        margin: auto;
        padding: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .carrier-action a {
        border-radius: 10px;
        padding: 15px 40px;
        color: #3C3835;
        background-color: #fab500;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }

}

@media (max-width: 1200px) {

    .about-us-container {
        width: 90%;
    }

    .about-us-benefits {
        flex-direction: column;
    }

    .about-us-headline {
        margin-top: 30px;
    }

    .about-us-headline h3 {
        font-size: 30px;
    }

    .about-us-headline h4 {
        font-size: 20px;
        line-height: 24px;
        margin: 16px 0 40px;
    }

    .benefit-item {
        margin: auto;
    }


    .benefit-item h4 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .benefit-item p {
        font-size: 16px;
        line-height: 24px;
        padding: 14px 20px;
        text-align: center;
    }

    .about-us-carriers {
        width: 90dvw;
        margin: auto;
        padding-bottom: 10dvw;
    }

    .about-us-carriers h4 {
        color: #3C3835;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .about-us-carriers ol {
        margin-left: 20px;
    }

    .about-us-carriers ol li {
        font-size: 16px;
        color: #3C3835;
        font-weight: 400;
        line-height: 28px;
        margin-left: 10px;
    }

    .carrier-action {
        margin: auto;
        padding: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .carrier-action a {
        border-radius: 10px;
        padding: 10px 0px;
        width: 100%;
        color: #3C3835;
        background-color: #fab500;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }
}
