.payment-prices-wrapper {
  .pagetitle {
    font-weight: 400;
    font-size: 22px !important;
    line-height: 16px !important;
    border-bottom: 0;
    margin: 0;
  }
}

.payment-prices-row {
  display: flex;
  justify-content: space-between;

  .ticket-price-container .currency-style,
  .ticket-price-container .defaultPrice {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }
}

.payment-price-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}
