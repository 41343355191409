@mixin resp($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

.peopleAreTalkingSection {
  .comments-carousel {
    width: 560px;
    max-height: 382px;
  }

  @include resp(560px) {
    .comments-carousel {
      width: 320px;
    }
  }

  .peopleAreTalkingContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;

    .peopleAreTalkingTextContainer {
      padding: 136px 0px;
      background-color: #fab500;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include resp(550px) {
        display: block;
      }
      h3 {
        font-weight: 900;
        font-size: 40px;
        line-height: 48px;
        color: #28221e;
        margin-bottom: 30px;
        @include resp(550px) {
          font-weight: 700;
          font-size: 32px;
          line-height: 32px;
          padding-top: 64px;
          margin-bottom: 25px;
        }
      }
    }
    .carouselWrapper {
      display: flex;
      justify-content: center;
      align-content: center;
      text-align: center;
      max-width: 560px;
      width: 100%;
      margin: 0 auto;
    }
    .slick-prev::before {
      content: url("../../../media/arrow_back_ios.svg") !important;
      color: black;
      font-size: 20px;
      left: 300px;
    }
    .slick-next::before {
      content: url("../../../media/arrow_forward_ios.svg") !important;
      color: black;
      font-size: 20px;
    }
  }
}
