.reservation-depart-page-container {
  .edit-search-container {
    flex-direction: column;

    @media screen and (max-width: 1112px) {
      padding: 10px;
    }

    .search-header {
      flex-grow: 1;
      width: 100%;
    }
    .search-container {
      width: 100%;
      flex: 1;

      .Search__container {
        width: 100%;
      }

      .searchform-wrapper {
        background: #fff;
        padding: 20px 0;
        max-width: 800px;
        width: 100%;
        flex-grow: 1;
        margin: 0 auto;

        @media screen and (max-width: 1112px) {
          background: transparent;

          .fieldlabel-wrapper,
          .labelwrapper {
            background-color: #f5f5f5;
          }
        }
      }

      .Search--Form {
        position: static;
      }
    }

    .mobile-search-button button {
      bottom: -36px;
    }
  }

  // Hide suncountry banner when editing search
  .suncountry-banner-container.sticky-edit-search-position {
    display: none;
  }

  .reservation-section-wrapper {
    background-color: #f5f5f5;
    position: relative;
    top: -50px;
    height: auto;
  }
  background-color: #f5f5f5;
  height: 100%;
  .itinerarySingleRow-collapsible-button-container {
    margin-top: 10px;
  }
  .Section-container {
    position: sticky;
    top: 85px;
    z-index: 999;
  }
  .reservation-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    position: sticky;
    top: 126px;
    z-index: 1000;

    @media screen and (max-width: 1199px) {
      top: 136px;
    }

    @media screen and (max-width: 520px) {
      top: 96px;
      margin-bottom: 50px;
    }
  }
  .search-filters-wrapper {
    position: sticky;
    top: 180px;
    z-index: 1;
  }

  .suncountry-banner-container {
    display: none !important;
  }


  .reservation-depart-wrapper {
    .reservation-body {
      display: flex;
      flex-direction: column;

      .departure-date {
        margin-top: 40px;
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-size: 20px;
        font-weight: 400;
      }

      @media screen and (max-width: 1199px) {
        margin-top: 30px;
      }
    }

    display: flex;

    .itinerary-list-container {
      * {
        outline: none !important;
      }
      flex: 3;
      padding-top: 10px;
      background-color: #f5f5f5;

      @media screen and (max-width: 1199px) {
        padding: 0 10px;
        padding-top: 40px;
      }
    }
    .filters-container {
      flex: 1;
      margin-left: 20px;
    }
    .reservation-container {
      flex: 1;
    }

    .filters-mobile-container {
      display: none;
      align-items: center;
      margin: 0px 20px;
      padding: 10px 0px;
      border-bottom: 1px solid #cdccc8;
      .text {
        padding-left: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
    @media screen and (max-width: 800px) {
      .filters-container {
        display: none;
      }
      .filters-mobile-container {
        display: flex;
        justify-content: flex-end;

        .filters-mobile-wrapper {
          cursor: pointer;
        }
      }
    }
  }

  .segment-date {
    display: none;
  }
}
@media screen and (max-width: 330px) {
  .itinerary-list-container {
    padding-top: 40px;
  }
}

@media screen and (max-width: 414px) {
  .reservation-depart-page-breadcrumbs {
    display: none;
  }
}
