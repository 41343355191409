@import "../../../constants/variables.scss";

.payment-page-breadcrumbs .breadcrumbs-container {
  max-height: 45px !important;

  @media screen and (max-width: 520px) {
    display: none !important;
  }
}

.payment-summary-container {
  @media screen and (max-width: 520px) {
    margin-top: 20px;
  }
}

.info-text-wrapper {
  padding-top: 10px;
}

.payment-buttons {
  width: 50%;
  margin: 20px auto;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.leg-payment-wrapper {
  .itinerarySingleRow-container {
    margin: 0;
    width: 100%;
  }
}

.inline-payment-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 65px;
  margin-top: 60px;

  @media screen and (max-width: 520px) {
    margin-top: 0 !important;

    .loyalty-reward-wrapper {
      display: none;
    }
  }

  .payment-prices-wrapper,
  .loyalty-reward-wrapper {
    width: 100%;

    .pagetitle {
      margin: 0 !important;
    }
  }

  .loyalty-reward-wrapper .pagetitle {
    text-transform: uppercase;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 23px !important;
    border: 0;
  }

  .loyalty-reward-wrapper .loyalty-reward-container {
    background: #4d4d4d;
    border-radius: 8px;
    padding: 20px 35px;
    color: #fff;
  }
}

.confirm-button {
  display: none;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 7px 86px;
  border-style: none;

  background: #fab500;
  border-radius: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;

  color: #000000;

  @media screen and (max-width: 520px) {
    display: block;
  }
}

.payment-capture-container .mobile-payment-capture-header {
  display: none;
}

@media screen and (max-width: 520px) {
  .payment-capture-container {
    display: none;

    .mobile-payment-capture-header {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 16px;
      /* identical to box height, or 73% */

      color: #000000;
      margin-top: 20px;
    }

    .credit-card-form-container {
      padding-top: 0;
      @media screen and (max-width: $breakpoint-handheld-device) {
        width: 100% !important;
      }
    }

    .credit-card-form__wrapper {
      padding: 10px;

      .credit-card-form__header {
        display: none;
      }

      .credit-card-form__fieldset-wrapper {
        padding-left: 0;
      }
    }
  }
}
