.tax-container {
  border-bottom: 1px solid #cccccc !important;

  .tax-container__label {
    font-weight: 400;
  }
}
.tax-container .currency-style,
.tax-container .defaultPrice {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
}

.fee-container {
  margin-top: 10px;
  .fee-container__label,
  .currency-style,
  .defaultPrice {
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 16px !important;
  }
}

.total-price-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  border-top: 1px solid #cccccc;
  padding-top: 10px;
  .total-price-container__label,
  .currency-style,
  .defaultPrice {
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 16px !important;
  }
}

.totalprice-price-container {
  text-align: right;

  .loyalty-style {
    display: flex;
    align-items: flex-end;
    font-size: 12px!important;
  }
}

.totalprice-points-balance {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: flex-start;

  .loyalty-style {
    display: flex;
    align-items: flex-end;
    padding-top: 5px;
    font-size: 12px!important;
  }

  .defaultPrice {
    font-size: 24px;
  }
}

.total-price-balance-points {
  padding: 10px 0;
  font-weight: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

}

.totalprice-promocode-container {
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.totalprice-total-price-container {
   margin-bottom: 20px;
}

.totalprice-total-container {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalprice-fees-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .defaultPrice {
    font-size: 24px;
  }
}

.totalprice-taxes-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3c3835;

  .loyalty-style {
    display: flex;
    align-items: flex-end;
    font-size: 12px!important;
    padding-top: 1px;
    font-weight: 500!important;
  }

  .defaultPrice {
    font-size: 24px;
  }
}

.totalprice-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0px;
}

.totalprice-voucher-code-container {
  padding: 10px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalprice-total-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.totalprice-name-container {

}

.totalprice-description {
  font-size: 12px;
  line-height: 18px;
}

.totalprice-total-payment-container {
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .loyalty-style {
    display: flex;
    align-items: flex-end;
    font-size: 12px!important;
  }

  .defaultPrice {
    font-size: 24px;
  }
}

.totalprice-total-payment {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.payment-price-section {

  .loyalty-style {
    display: flex;
    align-items: flex-end;
    font-size: 12px!important;
    padding-top: 2px;
    padding-left: 1px!important;
    font-weight: 500!important;
  }
}