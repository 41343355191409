@import "../../styles/colors.scss";
@import "../../styles/mixin.scss";
.motorcoachPage {
  width: 100%;
  &__content {
    width: 100%;
    .inner {
      padding: 88px 0 100px 0;
      background-color: #fafafa;
      @include resp(500px) {
        padding: 32px 0 40px 0;
        background-color: #fff;
      }
    }
    .hero {
      width: 80%;
      margin: 0 auto;
      @include resp(768px) {
        width: 90%;
      }
      text-align: center;
      .motorcoachPage__pageTitle {
        font-weight: 900;
        font-size: 84px;
        color: $color-landline-gray;

        @include resp(768px) {
          font-size: 64px;
        }
        @include resp(500px) {
          font-size: 48px;
        }
        @include resp(425px) {
          font-size: 40px;
        }
      }
      .motorcoachPage__heroImage {
        width: 80%;
        max-width: 937px;
        margin-top: 100px;
        @include resp(768px) {
          width: 90%;
        }
        @include resp(500px) {
          margin-top: 40px;
        }
      }
    }

    &__texts {
      width: 90%;
      margin: 188px auto;
      max-width: 1060px;

      @include resp(768px) {
        margin: 0px auto;
      }
      .motorcoachPage__text {
        font-weight: 400;
        font-size: 20px;
        columns: $color-landline-gray;
        margin-top: 24px;
        @include resp(768px) {
          font-size: 16px;
        }
        a {
          cursor: pointer;
          color: $color-landline-orange;
        }
      }
    }
  }
}
.motorcoachPage__content__yourLandLineExperience {
  margin: 80px 0 0 0;
  width: 100%;
  background-color: $color-light-gray;
  color: $color-landline-gray;
  padding-bottom: 100px;
  .yourLandLineExperience__content {
    max-width: 1200px;
    width: 75%;
    margin: 0 auto;
    @include resp(1200px) {
      width: 90%;
    }
    &__sectionTitle {
      text-align: center;
      padding: 100px 0;
      font-weight: 900;
      font-size: 64px;
      @include resp(768px) {
        font-size: 36px;
      }
      @include resp(500px) {
        font-size: 24px;
        font-weight: 600;
        padding: 40px 0 0 0;
        margin: 0;
      }
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 100px;

      @include resp(768px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @include resp(500px) {
        margin-top: 40px;
      }
      img {
        width: 49%;
        @include resp(768px) {
          width: 100%;
        }
      }
      .yourLandLineExperience__texts {
        width: 43%;

        @include resp(768px) {
          width: 90%;
          margin: 40px 0;
        }
      }
      &:nth-child(odd) {
        flex-direction: row-reverse;
        @include resp(768px) {
          flex-direction: column;
        }
        .yourLandLineExperience__texts {
          margin: 0;
          @include resp(768px) {
            width: 90%;
            margin: 40px 0;
          }
        }
      }

      .yourLandLineExperience__texts__title {
        font-weight: bold;
        font-size: 40px;
        text-align: left;
        @include resp(768px) {
          font-size: 30px;
          text-align: left;
        }
      }
      .yourLandLineExperience__text {
        margin-top: 12px;
        font-weight: normal;
        font-size: 16px;
      }
      .bold {
        font-weight: 600;
      }
    }
  }
}

.motorcoachPage_airports_list {
  margin-left: 2rem;
  line-height: 1.25;
}
