@import "../../../constants/variables.scss";

.Wrapper--Promotion {
  background: #F5F5F5; // Off-white grey background
  padding: 20px 0;
  font-family: "Source Sans 3", sans-serif !important;

  width: 100%;

  @media screen and (min-width: 1200px) {
    display: flex;
    padding: 20px calc((100% - 1100px) / 2);
    padding-bottom: 50px;
  }
}

.Row--Promotion {
  display: grid;
  grid-template-columns: 1fr; // Single column by default (mobile view)
  gap: 24px; // Add spacing between items for mobile

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr; // Two columns for tablets
  }

  @media screen and (min-width: 850px) {
    grid-template-columns: repeat(4, 1fr); // Four columns for desktop
  }
}

.Quater--Promotion {

 
  
  flex: 1 1 100%; // Full width on mobile
  padding: 2px 10px;
  display: flex;
  gap: 8px; // Space between icon and description

  @media screen and (min-width: 1200px) {
    align-items: left !important;
    padding-left: 0px !important;
    
  }

  @media screen and (min-width: 600px) {
    padding: 20px;
    border-bottom: none; // Remove border for larger layouts
    text-align: center; // Center align for desktop
    flex-direction: column; // Stack icon and text vertically on tablets
  }

  & .iconWrapper {
    padding-left: 20px;
    width: 120px; // Icon size
    height: 120px;
    flex-shrink: 0; // Prevent icon from shrinking
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;

    & img {
      width: 120px; 
      height: 120px;
      position: relative;
      z-index: 2;


        @media screen and (max-width: 600px) {
          width: 60px;  // Reduce size on mobile
          height: 60px; // Keep consistent ratio
        }
    }
  }

  & .contentWrapper {
    flex-grow: 1; // Allow description to take remaining width
    text-align: left;
    width: 100%;

    & h3 {
      font-size: 24px;
      margin: 0 0 8px;
      font-weight: 600;
      color: #000;
    }

    & p {
      line-height: 1.5;
      font-size: 14px;
      color: #555;
    }
  }
}

